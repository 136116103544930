import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Utility from "../common/Utility";
import { __t } from "../../local/Language";
import SweetAlert  from 'react-bootstrap-sweetalert';
class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            errors: {},
            lang: "en",
            showsuccess:false,
            title:"",
            alert_messege:""
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleValidation = this.handleValidation.bind(this)
        this.submit = this.submit.bind(this)
        this.showSuccessMsg=this.showSuccessMsg.bind(this);
    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleValidation() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        if (fields.errors === "not valid") {
            formIsValid = false;
            errors["email"] = __t("email_pwd_empty");
        }
        //Email
        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") == -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = __t("chk_valid_email");
            }
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = __t("chk_empty_email");
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    submit() {
        var domain = "";
        var url = window.location.href;
        url = url.split('/')
        var subDomain = url[2]
       // console.log(subDomain)
        
        if (this.handleValidation()) {
            let pid = localStorage.getItem("parent_id");
            fetch(Utility.baseUrl() + "insertPasswordToken?templatenme=forgotpassword&email=" + this.state.email+"&domain="+subDomain+"&pa="+pid, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
            })
                .then(response => response.json())
                .then(responseJson => {
                   
                    if (responseJson.code === 200) {                     
                      
                       this.setState({
                           showsuccess:true,
                           title:"Successfully sent.",
                           alert_messege:"A password reset link sent to your email.please check your email."
                       })
                        this.props.Forgottoggle();
    
                    } else if (responseJson.code === 204) {
                        this.setState({
                            showsuccess:true,
                            title:"Invalid Email!",
                            alert_messege:""
                        })
                    } 
                })
        } else {
        
        }

    }
    showSuccessMsg(){
        this.setState({
          showsuccess:false,
          modal:false,
          
        }) 
      }

      componentWillReceiveProps(){
        this.setState({
               errors: {}
        })
      }

    render() {

        return (
            <div>
                <Modal isOpen={this.props.isOpenforgotPass} toggle={this.props.Forgottoggle} className={this.props.className}>
                    <ModalHeader toggle={this.props.Forgottoggle} className="center100">Forgot Password</ModalHeader>
                    <ModalBody>
                        <p>Type your Email here</p>
                        <div className="form-group pos_rel">
                        <img src="/Assets/images/envelope.png" className="input_img"/>
                            <input
                                type="email"
                                name="email"
                                value={this.state.email}
                                className="form-control paddingleft40"
                                placeholder="Email"
                                required
                                onChange={this.handleChange}
                            />
                        </div>
                        <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                        <div className="flx_align_center mtop_10">
                        <button
                            type="submit"
                            className="btn btn_orange block full-width m-b"
                            onClick={this.submit}
                        >
                            Submit
          </button>
          </div>
                    </ModalBody>

                </Modal>
                <SweetAlert icon={this.state.icon} title={this.state.title} show={this.state.showsuccess} onConfirm={this.showSuccessMsg}>
  {this.state.alert_messege}
</SweetAlert>
            </div>
        );
    }
}

export default ForgotPassword;