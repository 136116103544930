import React, { Component } from "react";
import StripeCheckout from "react-stripe-checkout";
import Utility from "../common/Utility";
import $ from "jquery";
class StripeButton extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      publishableKey: props.publishableKey,
      amount: typeof props.amount != "undefined" ? props.amount : 0,
      subsid: props.subsid,
      hostname: ''
    };
  }

  componentDidMount() {
    if (window.location && window.location.hostname) {
      let location = window.location;
      if (location.hostname.indexOf("pushpolitics.org") !== -1 || location.hostname.indexOf("csaeconnect.ca") !== -1) {
        let hostname = location.hostname;
        if (hostname.indexOf('admin') !== -1) {
          hostname = hostname.split("admin").join("www");
        }
        this.setState({ hostname })
      }
    }
  }
  componentWillReceiveProps(props) {
    this.setState({ amount: props.amount });
  }
  onToken = async token => {
    let prntid = localStorage.getItem("parent_id");
    const body = {
      subsid: this.state.subsid,
      token: token,
      type: "new",
      parent_id: prntid
    };
    let actkn = localStorage.getItem("token");
    $("#loader_wrap").show()
    let res = await Utility.executeRequest("stripe-payment", body, actkn);
    if (res.code == 200) {
     // console.log("Payment Success");
      localStorage.setItem("client_id", res.client_id);
      this.props.showSuccessMsg(true);
    } else {
     // console.log("Payment Error");
      this.props.showSuccessMsg(false);
    }
    $("#loader_wrap").hide();
  };

  render() {
    if (this.state.amount > 0) {
      return (
        <StripeCheckout
          label="Pay With Stripe" //Component button text
          name={this.state.hostname} //Modal Header
          description="Upgrade to a premium account today."
          panelLabel="Pay" //Submit button in modal
          amount={Math.round(this.state.amount * 100)}
          currency={'CAD'}
          token={this.onToken}
          stripeKey={this.state.publishableKey}
          image="" //Pop-in header image
          billingAddress={true}
        />
      );
    } else {
      return "";
    }
  }
}
export default StripeButton;
