import React from "react";
export default class HomeSlider extends React.Component {
  render() {
    return (
      <div className="slider_section">
        <a href="#" target="_blank">
          {" "}
        </a>

        <div id="slider">
        {/* <div className="slider_main_heading">
                <h1>
                    Software to lobby policy makers and build progressive
                    movements.
                  </h1>
                  <p>
                    Nam ultrices pellentesque facilisis. In semper tellus mollis
                    nisl pulvinar vitae vulputate lorem consequat. Fusce odio
                   
                  </p>
                </div> */}
                {/* <div className="slider_phone_gif_wrap">
          <img
            src="/Assets/images/phone_frame.png"
            className="img_phone_holder"
          />
          <img
            src="/Assets/images/pushpolitics_gif1.gif"
            className="phone_holder_roted"
          />
          </div> */}
          <div className="slides">
            <div className="slider">
              {/* <img src="/Assets/images/logo1.png" style={{'position':'absolute', 'bottom':'100px', 'left':'50px', 'width':'450px'}}/> */}
              {/* <div className="legend" /> */}
              {/* <div className="content">
                <div className="content-txt">
                  <h1>
                    Software to lobby policy makers and build progressive
                    movements.
                  </h1>
                  <h2>
                    Nam ultrices pellentesque facilisis. In semper tellus mollis
                    nisl pulvinar vitae vulputate lorem consequat. Fusce odio
                    tortor, pretium sit amet auctor ut, ultrices vel nibh.
                  </h2>
                </div>
              </div> */}
              <div className="slider_image">
                
                <img src="/Assets/images/Picture111.jpg " />
              </div>
            </div>
            <div className="slider">
            <img src="/Assets/images/logo2.png" style={{'position':'absolute', 'bottom':'100px', 'left':'50px', 'width':'450px'}}/>

              {/* <div className="legend" /> */}
              <div className="content">
                {/* <div className="content-txt">
                  <h1>Lorem ipsum dolor</h1>
                  <h2>
                    Nam ultrices pellentesque facilisis. In semper tellus mollis
                    nisl pulvinar vitae vulputate lorem consequat. Fusce odio
                    tortor, pretium sit amet auctor ut, ultrices vel nibh.
                  </h2>
                </div> */}
              </div>
              <div className="slider_image">
                <img src="/Assets/images/pics2.jpg " />
              </div>
            </div>
            <div className="slider">
            <img src="/Assets/images/logo3.png" style={{'position':'absolute', 'bottom':'100px', 'left':'50px', 'width':'450px'}}/>

              {/* <div className="legend" /> */}
              <div className="content">
                {/* <div className="content-txt">
                  <h1>Lorem ipsum dolor</h1>
                  <h2>
                    Nam ultrices pellentesque facilisis. In semper tellus mollis
                    nisl pulvinar vitae vulputate lorem consequat. Fusce odio
                    tortor, pretium sit amet auctor ut, ultrices vel nibh.
                  </h2>
                </div> */}
              </div>
              <div className="slider_image">
                <img src="/Assets/images/pics3.jpg " />
              </div>
            </div>
            <div className="slider">
            <img src="/Assets/images/logo4.png" style={{'position':'absolute', 'bottom':'100px', 'left':'50px', 'width':'450px'}}/>

              {/* <div className="legend" /> */}
              {/* <div className="content">
                <div className="content-txt">
                  <h1>Lorem ipsum dolor</h1>
                  <h2>
                    Nam ultrices pellentesque facilisis. In semper tellus mollis
                    nisl pulvinar vitae vulputate lorem consequat. Fusce odio
                    tortor, pretium sit amet auctor ut, ultrices vel nibh.
                  </h2>
                </div>
              </div> */}
              <div className="slider_image">
                <img src="/Assets/images/pics4.jpg " />
              </div>
            </div>
          </div>
          <div className="switch">
            <ul>
              <li>
                <div className="on" />
              </li>
              <li />
              <li />
              <li />
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
