import React from 'react';
export default class Partners extends React.Component{
    render(){
        return(
                    <div>
<div className="our_partners">
<h1>Integration Partners</h1>
  <ul className="partners_section">
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>
   <li className=""><img src="/Assets/images/logo_placeholder.jpg" alt="" className=""/></li>

</ul>
</div>
</div>
        )}}