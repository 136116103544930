import React from "react";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { __t } from "../../local/Language";
import Utility from "../common/Utility";
import $ from "jquery";
class SubDomain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      errors: {},
      subDomain: Utility.domain,
      checkout_button: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.getSubDomain = this.getSubDomain.bind(this);
    this.gotoPriceTable = this.gotoPriceTable.bind(this);
  }
  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["search"]) {
      formIsValid = false;
      errors["search"] = __t("please_enter_any_domain");
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  getSubDomain() {
    if (this.handleValidation()) {
      fetch(Utility.baseUrl() + "checkdomainforfrontendUser", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(this.state)
      })
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.code == 204) {
            localStorage.setItem("searchDomain", this.state.search);
            $("#subdomain_message").text("SubDomain is Available.");

            this.setState({
              checkout_button: true
            });
          } else {
            localStorage.removeItem("searchDomian");
            $("#subdomain_message").text("SubDomain is not Available.");
            this.setState({
              checkout_button: false
            });
          }
        })
        .catch(error => {
          this.setState({ errors: true });
        });
    } else {
    }
  }
  gotoPriceTable() {
    $("html,body").animate(
      {
        scrollTop: $("#priceTable").offset().top
      },
      "slow"
    );
  }
  render() {
    return (
      <div className="">
        <div className="pos_rel ">
          <div className="searchdomain_box ">
            <h1>Check Your Domain</h1>
            <div className="searchdomain_input ">
              <input
                type="search"
                id="search"
                name="search"
                placeholder="search here.."
                className="form-control bdr0"
                onChange={this.handleChange}
              />
              <div className="maindomain_iconwrap ">
                <h4 name="subDomain" value={Utility.domain}>
                  {Utility.domain}
                </h4>
                <span className="searchdomain_icon" onClick={this.getSubDomain}>
                  <i className="fa fa-search domainsearch_ico" />
                </span>
              </div>
            </div>

            <label
              className="text-danger label_domain_not"
              id="subdomain_message"
            />
            {this.state.checkout_button ? (
              <button
                className="btn btn-orange mleft10"
                id="checkout"
                onClick={this.gotoPriceTable}
              >
                Checkout
              </button>
            ) : (
              ""
            )}
          </div>
        </div>

        <span style={{ color: "red" }}>{this.state.errors["search"]}</span>
      </div>
    );
  }
}
export default SubDomain;
