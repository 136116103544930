import React from "react";
import { Redirect } from "react-router-dom";
import Utility from "../common/Utility";
import TopHeader from "../menu/TopHeader";
import PaypalExpressButton from "../checkout/PaypalButton";
import Payment_Success from "../checkout/payment_success";
import SweetAlert from "react-bootstrap-sweetalert";
import StripeButton from "./StripeButton";
export default class CheckOut extends React.Component {
  constructor() {
    super();
    this.state = {
      id: "",
      subscription: [],
      Button_Type: "",
      status: "started",
      subscription_plan: "",
      title: "",
      feature: "",
      price: "",
      subsid: "",
      payment_status: "",
      order_id: "",
      payment: [],
      payment_id: "",
      payer_id: "",
      payment_token: "",
      redirect: false,
      subDomain: "",
      duration: "",
      redirectToUpgrade: false,
      client_id: localStorage.getItem("client_id"),
      trans_title: "",
      showsuccess: false,
      redirect_page: false,
      transaction_key: false,
      display_paypal: false,
      display_stripe: false,
      public_key: "",
      amount: 0,
      tax_rate: 0,
      tax_label: 'Tax'
    };
    this.getSubscriptionData = this.getSubscriptionData.bind(this);
    // this.getOrder = this.getOrder.bind(this);
    this.showSuccessMsg = this.showSuccessMsg.bind(this);
    this.setgateway = this.setgateway.bind(this);
  }

  componentWillMount() {
    var id = this.props.match.params.id;
    if (this.alphanumeric(id)) {
      window.location.href = "/";
    } else {
      var clientID = localStorage.getItem("client_id");
      let actkn = localStorage.getItem("token");
      this.setgateway(actkn);
      if (
        clientID === null ||
        clientID === undefined ||
        clientID === "" ||
        clientID === "null"
      ) {
        this.getSubscriptionData(id);
      } else {
        var data = {
          subs_id: this.props.match.params.id,
          clientsubs_id: localStorage.getItem("client_id")
        };

        Utility.ExecuteData("checkSubscriptionForClient", data).then(
          responseJson => {
            if (responseJson.code == 202) {
              window.location.href = "/";
            } else {
              if (responseJson.code == 200) {
                this.getSubscriptionData(id);
              }
            }
          }
        );
      }
    }
  }
  async setgateway(actkn) {
    let res = await Utility.executeRequest(
      "checkStatusPaypalButton",
      {},
      actkn
    );
    if (res.code == 200) {
      this.setState(
        {
          display_paypal: res.status[0].paypal_status,
          display_stripe: res.status[0].status_stripe,
          public_key: res.status[0].public_stripe
        }
      );
    }
  }
  alphanumeric(inputtxt) {
    var letterNumber = /^[a-zA-Z]+$/;
    if (inputtxt.match(letterNumber)) {
      return true;
    } else {
      return false;
    }
  }

  showSuccessMsg(status) {
    if (status) {
      this.setState({
        showsuccess: false,
        modal: false,
        redirect: true
      });
    } else {
      this.setState({
        showsuccess: false,
        modal: false
      });
    }
  }
  getSubscriptionData(id) {

    fetch(Utility.baseUrl() + "subscriptiondetailsforclient?id=" + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.error) {
          window.location.href = "/";
        }
        if (responseJson.code === 200) {
          var list = responseJson.data;
          var tax_data = responseJson.tax_data;
          let tax_rate = tax_data.tax_rate;
          let tax_label = tax_data.tax_label;
          let newlist = new Array();
          list.map((v, k) => {
            var newfeature = new Array();
            if (typeof newlist[v.id] != "undefined") {
              newfeature = newlist[v.id].feature;
            }
            newfeature.push(v.title);

            newlist[v.id] = {
              title: v.subscriptions,
              price: v.price,
              feature: newfeature,
              duration: v.duration
            };
          });
          var my_array = newlist.filter(function (x) {
            return x !== (undefined || null || "");
          });
          this.setState({
            tax_rate, tax_label,
            subscription: my_array,
            id: this.props.id,
            subsid: this.props.match.params.id,
            duration: list[0].duration
          });

          //this.getOrder();
          var feature = [];
          this.state.subscription.map(i => {
            this.setState({
              price: i.price,
              title: i.title
            });
            feature.push(i.feature);
          });
          this.setState({
            feature: feature
          });
        } else if (responseJson.code === 204) {
        }
      });
  }
  // getOrder() {
  //   var feature = [];
  //   this.state.subscription.map(i => {
  //     this.setState({
  //       price: i.price,
  //       title: i.title
  //     });
  //     feature.push(i.feature);
  //   });
  //   this.setState({
  //     feature: feature
  //   });

  //   fetch(Utility.baseUrl() + "getorder", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(this.state)
  //   })
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       if (responseJson.code === 200) {
  //         this.setState({
  //           order_id: responseJson.id
  //         });
  //       } else if (responseJson.code === 204) {
  //       }
  //     })
  //     .catch(error => {
  //       this.setState({ errors: true });
  //     });
  // }
  // insertOrderTransaction() {
  //   fetch(Utility.baseUrl() + "TransactionforCancel", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(this.state)
  //   })
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       console.log(responseJson);
  //       if (responseJson.code === 200) {
  //         this.setState({
  //           showsuccess: true,
  //           trans_title: "Your transaction has been cancelled!",
  //           alert_messege: "please try again."
  //         });
  //       } else if (responseJson.code === 204) {
  //       }
  //     })
  //     .catch(error => {
  //       this.setState({ errors: true });
  //     });
  // }
  // insertClient(status) {
  //   fetch(Utility.baseUrl() + status, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(this.state)
  //   })
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       if (responseJson.code === 200) {
  //         this.setState({
  //           showsuccess: true,
  //           trans_title: "Your transaction has been Successful.",
  //           alert_messege: "please go to admin panel.",
  //           transaction_key: true
  //         });
  //       } else if (responseJson.code == 204) {
  //       }
  //     })
  //     .catch(error => {
  //       this.setState({ errors: true });
  //     });
  // }

  paymentStatus = (payment_status, data) => {
    // Handle -> Success/ Error /Cancel
    //console.log("asdf   ", payment_status, data);
    if (payment_status == "cancel" || payment_status == "error") {
      Utility.ExecuteData("TransactionforCancel", data).then(e => {
        this.setState({
          showsuccess: true,
          trans_title: "Your transaction has been cancelled!",
          alert_messege: "please try again."
        });
      });
    } else if (payment_status == "success") {
      this.setState({
        showsuccess: false,
        modal: false,
        redirect: true
      });
    }
  };
  render() {
    var fe;
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/payment_success" />;
    }
    const { redirectToUpgrade } = this.state;
    if (redirectToUpgrade) {
      return (
        <Redirect push to={"/upgradePlan/" + this.props.match.params.id} />
      );
    }
    var price;
    var data = {
      userid: this.state.id,
      orderid: this.state.order_id,
      subsid: this.state.subsid,
      status: "started",
      feature: this.state.feature,
      subscription_list: this.state.subscription
    };
    return (
      <div>
        <TopHeader
          buttonType={this.props.ButtonType}
          getidfromtoken={this.props.getUserInfo}
        />

        <div className="checkout_table_box">
          <div className="checkout_btn">
            <h4>YOUR SHOPPING CART</h4>
          </div>
          <table className="table table-border checkout_tbl">
            <thead>
              <tr className="checkout_tbl_td">
                <th>Item</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody className="checkout_body">
              {this.state.subscription
                ? this.state.subscription.map(i => {
                  console.log(i);
                  price = i.price;
                  fe = i.feature.toString();
                  return (
                    <tr>
                      <td>{i.title}</td>
                      <td>
                        features - {fe} / {i.duration} days
                        </td>
                      <td>${i.price}</td>
                    </tr>
                  );
                })
                : ""}
            </tbody>
          </table>
          <div className="flx_end">
            <label>{this.state.tax_label + " (" + this.state.tax_rate + "%): $" + price * this.state.tax_rate / 100}</label>
          </div>
          <div className="flx_end">
            <label>{"Total Price: $" + (price + price * this.state.tax_rate / 100)}</label>
          </div>
          <div className="flx_end">
            {this.state.display_paypal == "active" && this.state.subsid ? (
              <PaypalExpressButton
                data={data}
                paymentStatus={this.paymentStatus}
                showSuccessMsg={this.showSuccessMsg}
              />
            ) : (
                ""
              )}

            {this.state.display_stripe == "active" && this.state.subsid ? (
              <StripeButton
                amount={(price + price * this.state.tax_rate / 100)}
                publishableKey={this.state.public_key}
                subsid={this.state.subsid}
                user_id={this.state.id}
                showSuccessMsg={this.showSuccessMsg}
                paymentStatus={this.paymentStatus}
              />
            ) : (
                ""
              )}
          </div>
        </div>
        <SweetAlert
          title={this.state.trans_title}
          show={this.state.showsuccess}
          onConfirm={this.showSuccessMsg}
        >
          {this.state.alert_messege}
        </SweetAlert>
      </div>
    );
  }
}
