import React from "react";
import { NavLink } from "react-router-dom";
import LoginPopUp from "../visit/LoginPopUp";
import UserLoginButton from "../visit/userLoginButton";
//import SweetAlert from 'sweetalert-react';
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlert2 from "react-bootstrap-sweetalert";
import SweetAlert3 from "react-bootstrap-sweetalert";
import SweetAlert4 from "react-bootstrap-sweetalert";
import ForgotPassword from "../visit/ForgotPasswordPopUp";
import Utility from "../common/Utility";
import $ from "jquery";
import content from "../../content";
//import VerifyingEmailPop from "../visit/verifyingEmailPop";
export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      userPermissions: [],
      button_Type: "",
      show: false,
      showsuccess: false,
      showsuccess2: false,
      showsuccess3: false,
      forgot_modal: false,
      user_msg: ""
      //  showCancel:true
    };
    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.cancelLogout = this.cancelLogout.bind(this);
    this.logoutSuccess = this.logoutSuccess.bind(this);
    this.showRegisterMsg = this.showRegisterMsg.bind(this);
    this.forgot_toggle = this.forgot_toggle.bind(this);
    this.sendlink = this.sendlink.bind(this);
    this.showRegisterMsg2 = this.showRegisterMsg2.bind(this);
    this.showRegisterMsg3 = this.showRegisterMsg3.bind(this);
  }
  componentWillMount() {
    let host = window.location.host;
    console.log(window.location.host, window.location.hostname)
    let domainContent = content[host];
    var title = document.getElementById("header-title");
    title.innerHTML = domainContent.title;
    // console.log(this.props);
    // console.log(this.props.loginType);
    if (this.props.loginType == "login") {
      this.setState({
        modal: !this.state.modal
      });
    } else {
      if (this.props.loginType == "forgot") {
        this.setState({
          forgot_modal: !this.state.forgot_modal
        });
      }
    }

    if (window.location.href.indexOf("/logout") != -1) {
      localStorage.clear();
      window.location.href = "/";
    }
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  forgot_toggle() {
    // this.props.toggle();
    this.setState({
      forgot_modal: !this.state.forgot_modal
    });
  }

  logout(name) {
    if (name) {
      this.setState({
        showsuccess: true
      });
    } else {
      this.setState({
        show: true
      });
    }
  }
  cancelLogout() {
    this.setState({
      show: false
    });
  }
  gotoTable(name) {
    //console.log(name)
    var _currentUrl = window.location.href;
    var _currentPage = _currentUrl.split("/");
    // console.log(_currentPage);
    if (_currentPage.length > 4) {
      window.location.href = "/";
    }
    if (name == "home") {
      window.location.href = "/";
    } else {
      $("html,body").animate(
        {
          scrollTop: $("#" + name).offset().top - 90
        },
        "slow"
      );
    }
  }
  logoutSuccess() {
    this.setState({
      show: false
    });
    localStorage.clear();
    if (window.location.href.indexOf("checkout")) {
      window.location.href = "/";
    }

    this.props.getidfromtoken();
  }
  showRegisterMsg() {
    this.setState({
      showsuccess: false
    });
  }
  showRegisterMsg2() {
    this.setState({
      showsuccess2: false
    });
  }
  showRegisterMsg3() {
    this.setState({
      showsuccess3: false
    }, () => {
      this.gotoTable("priceTable")
    });
  }
  go_dashboard = () => {
    var verified = localStorage.getItem("verified")
    var message = "Please buy a Subscription plan."

    if (verified == "0") {
      message = "Email ID is not Verified. Check your inbox for verification email."
    }
    this.setState({
      showsuccess3: true,
      user_msg: message
    }, () => {
      this.gotoTable("priceTable")
    });


  }
  sendlink() {
    var subDomain = window.location.hostname;
    var user_id = localStorage.getItem("user_id");
    fetch(Utility.baseUrl() + "insertPasswordTokenForEmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        domain: subDomain,
        user_id: user_id,
        parent_id: localStorage.getItem("parent_id")
      }

      )

    })
      .then(response => {
        if (response.status == 200) {
          this.setState({
            showsuccess2: true,
            title: "Link has been sent to your mail id"
          })
        }
        else {
          this.setState({
            showsuccess2: true,
            title: "Email is not valid"
          })
        }
      }).catch(e => {
        this.setState({
          showsuccess2: true,
          title: "No Internet connection!"
        })
      })
  }
  goToDashboard = () => {
    var token = localStorage.getItem("token");
    if (token) {
      var loginurl = Utility.adminUrl() + "login/" + token
      window.location.href = loginurl;
    } else {
      window.location.href = ""
    }


  }
  render() {

    var token = localStorage.getItem("token");
    var loginurl = Utility.adminUrl() + "login/" + token
    var u_data = localStorage.getItem("user_data");
    var verified = localStorage.getItem("verified")
    var subscription_div = (
      <div className={"alert alert_top alert-warning"} id="sub_alert">
        <strong className="verifymail">
          Email not yet verified
        </strong>
        <span> <button
          onClick={this.sendlink}
          className="btn btn-danger btn-sm"
          style={{ float: "right" }}
        >
          Click to send link
            </button></span>
      </div>
    );

    var token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    var url = Utility.baseUrl(token);
    var backendurl = Utility.backendUrl();
    //console.log(url);
    let host = window.location.host;
    let domainContent = content[host];

    return (
      <div>

        <header className="header_fix" style={{ 'background': 'rgba(152,26,155,1)' }}>
          {u_data && verified && verified == "0" ?
            subscription_div
            : ""}
          <div className="container">
            <div className="nav_bar">
              <div className="nav-header">
                <div className="nav-title">
                  <img src={domainContent.logo} className="pp_logo" />
                </div>
              </div>
              <div className="nav-btn">
                <label htmlFor="nav-check">
                  <span />
                  <span />
                  <span />
                </label>
              </div>


              <input type="checkbox" id="nav-check" />
              <div className="nav-links">
                {this.props.userType === "client" && verified == "1" && this.props.clientID ? (
                  <a href="#" onClick={this.goToDashboard} target="_blank" >
                    Dashboard
                  </a>
                  // <button onClick={this.go_dashboard}>
                  //   Dashboard
                  // </button>

                ) :
                  this.props.userType == "admin" || this.props.userType == "reseller" ?
                    <a href="#" onClick={this.goToDashboard} target="_blank" >
                      Dashboard
              </a>
                    :
                    this.props.userType === "client" ?
                      (
                        <li onClick={this.go_dashboard} className="dashboard_btn">
                          Dashboard
                </li>
                      )
                      : ""}
                <a href="#" onClick={() => this.gotoTable("home")}>
                  Home
              </a>
                {/* <a href="#" onClick={() => this.gotoTable("widget")}>
                Widgets
              </a> */}
                <a href="#" onClick={() => this.gotoTable("campaign")}>
                  Campaigns
              </a>
                <a href="#" onClick={() => this.gotoTable("priceTable")}>
                  Pricing
              </a>
                <UserLoginButton
                  buttontype={this.props.buttonType}
                  toggle={this.toggle}
                  logout={() => this.logout()}
                />
              </div></div>
          </div>
        </header>
        <LoginPopUp
          isOpen={this.state.modal}
          toggle={this.toggle}
          getid={this.props.getidfromtoken}
          showRegisterMsg={() => this.logout("Register")}
        />


        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, logout!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.logoutSuccess}
          onCancel={this.cancelLogout}
          show={this.state.show}
        />
        <SweetAlert2
          success
          title="Registered Successfully!"
          show={this.state.showsuccess}
          onConfirm={this.showRegisterMsg}
        />
        <SweetAlert3
          success
          title={this.state.title}
          show={this.state.showsuccess2}
          onConfirm={this.showRegisterMsg2}
        />
        <SweetAlert4
          type="warning"
          title={this.state.user_msg}
          show={this.state.showsuccess3}
          onConfirm={this.showRegisterMsg3}
        />
        <ForgotPassword
          isOpenforgotPass={this.state.forgot_modal}
          Forgottoggle={this.forgot_toggle}
        />
        <div className="loader_wrap" id="loader_wrap" style={{ "display": "none" }}>
          <div className="loader_show"></div>
        </div>
      </div>
    );
  }
}
