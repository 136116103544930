import React from 'react';
import content from "../../content";
export default class About extends React.Component {
    render() {
        let host = window.location.host;
        let domainContent = content[host];
       
        return (
            <div className={"container"}>
            <div className="features_section container" style={{ 'background': 'white', 'marginBottom': '20px', 'padding': '30px' }}>
                <div id="about">
                    <h1 style={{ 'color': 'rgba(152,26,155,1)', 'marginBottom': '20px', 'font-family': 'Gotham' }}> ABOUT</h1>
                    <h6 style={{
                        'color': 'rgba(83,34,84,1)',
                        'fontSize': '15px', 'font-family': 'Gotham-Light', 'fontWeight': '390', 'marginBottom': '20px', 'width': '100%'
                    }}>
                        {/* We have developed a platform with busy association executives in mind, exclusively for the <br />Canadian marketplace. CSAE Digital Advocacy was designed for the Canadian political system with <br />full compliance for Canadian laws and regulations. All supporter data is housed on Canadian servers <br />with industry leading security in place. */}
                        {domainContent.about}
                    </h6>

                    {/* <a href="#" >  <h5 style={{ 'color': 'rgba(83,34,84,1)', 'fontSize': '15px','font-family': 'Gotham-Light', 'fontWeight': '390', 'marginBottom': '20px' }}>PRIVACY POLICY</h5></a>
                    <a href="#" >   <h5 style={{ 'color': 'rgba(83,34,84,1)', 'fontSize': '15px', 'font-family': 'Gotham-Light', 'fontWeight': '390', 'marginBottom': '20px' }}>CONTACT</h5></a>
                    <a href="#" >   <h5 style={{ 'color': 'rgba(83,34,84,1)', 'fontSize': '15px', 'font-family': 'Gotham-Light', 'fontWeight': '390', 'marginBottom': '20px' }}>FAQs</h5></a>
                    <a href="#" >   <h5 style={{ 'color': 'rgba(83,34,84,1)', 'fontSize': '15px', 'font-family': 'Gotham-Light', 'fontWeight': '390', 'marginBottom': '20px' }}>YouTube link for support</h5></a> */}
                </div>

            </div>
            </div>
           
        )

    }
}