import React from "react";
import ReactDOM from "react-dom";
import paypal from "paypal-checkout";
import Utility from "../common/Utility";
const PayPalButton = paypal.Button.driver("react", { React, ReactDOM });
const env = "sandbox";
class PaypalExpressButton extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      data: props.data
    };
  }

  componentWillMount() {}

  onError = err => {
    this.props.paymentStatus("error", err);
  };
  payment = async () => {
    let actkn = localStorage.getItem("token");
   
    let res = await Utility.executeRequest(
      "createPayment",
      { subsid: this.state.data.subsid },
      actkn
    );
    return res.id;
  };
  onAuthorize = async (paypal_res, actions) => {
    let prntid = localStorage.getItem("parent_id");
    paypal_res.subsid = this.state.data.subsid;
    paypal_res["subsid"] = this.state.data.subsid;
    paypal_res["parent_id"] = prntid
 
    let actkn = localStorage.getItem("token");
    let res = await Utility.executeRequest("executePayment", paypal_res, actkn,prntid);
    this.onSuccess(res);
  };
  onSuccess = payment => {
    //console.log(payment)
    this.props.showSuccessMsg(true);
    localStorage.setItem("client_id", payment.client_id);
    this.props.paymentStatus("success", payment);
  };

  onCancel = data => {
    this.props.showSuccessMsg(false);
    this.props.paymentStatus("cancel", data);
  };

  render() {
    return (
      <div>
        <PayPalButton
          env={env}
          payment={this.payment}
          commit={true}
          onAuthorize={this.onAuthorize}
          onError={this.onError}
          onCancel={this.onCancel}
        />
      </div>
    );
  }
}

export default PaypalExpressButton;
