import React from "react";
import Utility from "../common/Utility";
import TopHeader from "../menu/TopHeader";
import SweetAlert4 from "react-bootstrap-sweetalert";
import $ from "jquery";

export default class Payment_Success extends React.Component {
  constructor() {
    super();
    this.state = {
      showsuccess3: false
    };
  }

  showRegisterMsg3 = () => {
    this.setState({
      showsuccess3: false
    });
  }
  gotoAdminPanel = async () => {
    let actkn = localStorage.getItem("token");
    let res = await Utility.executeRequest("is_email_verify", {}, actkn);
    if (res.code == 200) {
      let data = res.data;
      var message = "Please buy a Subscription plan.";
      if (data.verified === 0) {
        message = "Email ID is not Verified. Check your inbox for verification email."
        this.setState({
          showsuccess3: true,
          user_msg: message
        });
      } else {
        var token = localStorage.getItem("token");
        var loginurl = Utility.adminUrl() + "login/" + token
        window.open(loginurl, "_blank");
      }
    }
  }

  render() {
    return (
      <div>
        <TopHeader
          buttonType={this.props.ButtonType}
          getidfromtoken={this.props.getUserInfo}
        />
        <div className="main-content">
          {" "}
          <p style={{ marginTop: "150px" }}>
            <strong> Thank you for Registration!</strong>
            <p> Please login to Admin Panel to launching your website.</p>
          </p>
          <button
            onClick={this.gotoAdminPanel}
            type="submit"
            className="btn btn-primary block full-width m-b"
          >
            Go to Admin Panel
          </button>
        </div>

        <SweetAlert4
          type="warning"
          title={this.state.user_msg}
          show={this.state.showsuccess3}
          onConfirm={this.showRegisterMsg3}
        />
      </div>
    );
  }
}
