import React from 'react';
import Header from "../menu/TopHeader";

export default class NotFound extends React.Component {
  constructor(){
    super()
  }
  render(){
    return(
  <div className="error_page">
   <Header
          buttonType={this.props.ButtonType}
          getidfromtoken={this.props.getUserInfo}
          loginType={this.props.loginType}
        />
    <div className="error_main_section">
    <div className="error_sideone">
        <img src="/Assets/images/pagenotfound.png" className="error_icon"/>
    </div>
    <div className="error_sidetwo">
        <h1>Opps's</h1>
        <h3>Page Not Found</h3>
        <p>We are sorry but the page you are looking for does not exist.</p>
      
    </div>
    </div>
  </div>
    )
  }
}
