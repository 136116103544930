import React from "react";
import { Switch, Route, matchPath } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Home from "../components/home/Home";
import CheckOut from "../components/checkout/checkout";
import Utility from "../components/common/Utility";
import Payment_Success from "../components/checkout/payment_success";
import UpgradePlan from "../components/checkout/upgradeNewPlan";
import RenewPlan from "../components/checkout/renewPlan";
import Widget from "../components/widget/widget";
import ResetPassword from "../components/visit/ResetPassword";
import NotFound from "../components/common/notfound";

import { promises } from "fs";

export default class AppRoutes extends React.Component {
  constructor() {
    super();
    this.state = {
      Button_Type: "",
      id: "",
      user_type: "",
      token: "",
      https: false
    };
    this.token = localStorage.getItem("token");
    this.getUserInfo = this.getUserInfo.bind(this);
  }

  componentDidMount() {
    const url = window.location.origin
    if (!url.includes('localhost') && !url.includes('https')) {
      window.location = `https:${url.split(':')[1]}`

    } else {
      this.setState({ https: true }, () => {
        var url = window.location.href;
        url = url.split("/");
         var subDomain = url[2];
       
        // console.log(subDomain);
        if (subDomain == "pushpolitics.org" || subDomain=="www.pushpolitics.org") {
          // if (subDomain == "pushpolitics.org"||subDomain == "localhost:3001") {
          localStorage.setItem("parent_id", 1);
          this.getUserInfo();
        } else {

          var data = {
            subdomain: subDomain
          };
          this.getClient(data);
        }
      });

    }

  }
  getClient(data) {
    Utility.ExecuteData("checkdomainForClientID", data).then(res => {
      if (res) {
        localStorage.setItem("parent_id", res.data.id);
        //localStorage.setItem("parent_id", "1");
        this.getUserInfo();
      }
    });
  }
  getUserInfo() {
    var token = localStorage.getItem("token");
    var authData = localStorage.getItem("user_data");
    var get_client_id =
      localStorage.getItem("client_id") != "undefined" && localStorage.getItem("client_id") != "null" && localStorage.getItem("client_id") != null && localStorage.getItem("client_id") != ""
        ? localStorage.getItem("client_id")
        : "";
    authData = JSON.parse(authData);
    // console.log(authData);
    if (authData !== null) {
      localStorage.setItem("user_id", authData.id);

      if (authData.last_name) {
        this.setState({
          Button_Type: authData.first_name + " " + authData.last_name,
          id: authData.id,
          user_type: authData.user_type,
          client_id: get_client_id != "" ? get_client_id : authData.client_id
        });
      } else {
        this.setState({
          Button_Type: authData.first_name,
          id: authData.id,
          user_type: authData.user_type,
          client_id: get_client_id != "" ? get_client_id : authData.client_id
        });
      }
    } else {
      this.setState({
        Button_Type: null
      });
    }
  }
  render() {
    if (!this.state.https) {
      return null;
    }
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/"
            render={props => (
              <Home
                key={"pp_home1"}
                {...props}
                ButtonType={this.state.Button_Type}
                client_id={this.state.client_id}
                id={this.state.id}
                getUserInfo={this.getUserInfo}
                userType={this.state.user_type}
              />
            )}
          />
          <Route
            exact
            path="/reset_login"
            render={props => <Home key={"pp_home2"} {...props} ButtonType={this.state.Button_Type} loginType="login" getUserInfo={this.getUserInfo} userType={this.state.user_type} />}
          />
          />
          <Route
            exact
            path="/verifyemail/:token"
            render={props => <Home key={"pp_home3"} {...props} ButtonType={this.state.Button_Type} getUserInfo={this.getUserInfo} userType={this.state.user_type} />}
          />
          <Route
            exact
            path="/forgot_login"
            render={props => <Home {...props} ButtonType={this.state.Button_Type} loginType="forgot" getUserInfo={this.getUserInfo} userType={this.state.user_type} />}
          />
          <Route exact path="/resetpassword/:token" render={props => <ResetPassword {...props} ButtonType={this.state.Button_Type} getUserInfo={this.getUserInfo} id={this.state.id} />} />
          {localStorage.getItem("token") ? (
            <Route exact path="/checkout/:id" render={props => <CheckOut {...props} ButtonType={this.state.Button_Type} getUserInfo={this.getUserInfo} id={this.state.id} />} />
          ) : (
              <Redirect to="/" />
            )}
          {localStorage.getItem("token") ? (
            <Route exact path="/renewPlan/:id/:userid" render={props => <RenewPlan {...props} ButtonType={this.state.Button_Type} getUserInfo={this.getUserInfo} id={this.state.id} />} />
          ) : (
              <Redirect to="/" />
            )}
          {localStorage.getItem("token") ? (
            <Route exact path="/checkout/:id/:up" render={props => <CheckOut {...props} ButtonType={this.state.Button_Type} getUserInfo={this.getUserInfo} id={this.state.id} />} />
          ) : (
              <Redirect to="/" />
            )}
          {localStorage.getItem("token") ? (
            <Route exact path="/upgradePlan/:id" render={props => <UpgradePlan {...props} ButtonType={this.state.Button_Type} getUserInfo={this.getUserInfo} id={this.state.id} />} />
          ) : (
              <Redirect to="/" />
            )}
          {localStorage.getItem("token") ? (
            <Route exact path="/payment_success" render={props => <Payment_Success {...props} ButtonType={this.state.Button_Type} getUserInfo={this.getUserInfo} id={this.state.id} />} />
          ) : (
              <Redirect to="/" />
            )}
          <Route exact path="/widget" render={props => <Widget {...props} ButtonType={this.state.Button_Type} getUserInfo={this.getUserInfo} id={this.state.id} />} />

          <Route path="/:notfound" render={props => <NotFound {...props} ButtonType={this.state.Button_Type} id={this.state.id} getUserInfo={this.getUserInfo} userType={this.state.user_type} />} />
        </Switch>
      </div>
    );
  }
}
