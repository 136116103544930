import React from "react";
export default class Campaign extends React.Component {
  
  render() {
    return (
      <div className="features_section" style={{'background':'white','marginBottom':'20px','padding-top':'50px'}}>



       {/* <div className="our_campaign_section" id="campaign"> */}
       <div id="campaign">
         <div className="container">
      <h1 style={{'color':'rgba(152,26,155,1)', 'font-family': 'Gotham'}}> CAMPAIGNS</h1>
<h6 style={{'color':'rgba(83,34,84,1)', 'fontSize': '15px','fontWeight': '400','marginBottom':'20px' }}>
All campaigns start with an issue. </h6>

<h6 style={{'color':'rgba(83,34,84,1)', 'fontSize': '15px', 'font-family': 'Gotham-Light','fontWeight': '390','marginBottom':'20px'}}>Whether you are raising awareness or fighting back against cuts, our customizable platform allows you to <br/> choose political targets beyond the local representative or candidates to reach the most senior levels of<br/> decision making. </h6>

<h6 style={{'color':'rgba(83,34,84,1)', 'fontSize': '15px', 'font-family': 'Gotham-Light','fontWeight': '390','marginBottom':'20px'}}>We operate on the founding principle that all politics is local. By highlighting your issue as a concern of local <br/>residents, politicians will pay attention and take action.</h6>

<h6 style={{'color':'rgba(83,34,84,1)', 'fontSize': '15px', 'font-family': 'Gotham-Light','fontWeight': '390','marginBottom':'20px' }}>CSAE Digital Advocacy will help your supporters make local connections and raise the volume on your <br/>issue with decisionmakers. With this tool, your campaign will be able to build a dedicated campaign<br/> microsite, send letters to elected officials, and leverage social media for broader reach.</h6>

    <div className="camp_img_sectn" style={{}}>
      <div className="row">
      <div className="col-md-5">
        <div className="camp_imagecontent">
        <img src="/Assets/images/Picture1.png "style={{'width':'100%'}} />
        <h6>Embed our tools in your campaign website </h6>
        </div>
      </div>
      <div className="col-md-2"></div>
      <div className="col-md-5">
        <div style={{'display':'flex', 'position':'relative'}}>   
        <h6 style={{'width': '294px', 'marginLeft':'15px', 'font-family': 'Gotham-Light','fontWeight': '390'}}>Customize bilingual messages and launch simultaneous campaigns about different issues</h6> 
     <img src="/Assets/images/Picture11.png " style={{'position':'absolute','bottom':'-138px','left':'0'}} />
      <img src="/Assets/images/Picture21.png " />
        </div></div></div>
    
        </div>
</div>    
       {/* <div className="widget_discription_section wd50">
    <h1>Campaign</h1>
    <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
</div>
    <div className="Widget_section_phone wd50">
    <img src="/Assets/images/chartmap.png" className="chart" />
    </div> */}

</div>
      </div>
    );
  }
}
