import React from 'react';
import { NavLink } from 'react-router-dom';
export default class Features extends React.Component {
    render() {
        return (
            <section className="features_sectionbg">
            <div className="container">
            <div className="features_section" style={{'padding':'50px 0px'}}>
                <h1  style={{'color':'rgba(83,34,84,1)', 'fontSize': '20px','font-family': 'Gotham-Light','fontWeight': '300' ,'marginBottom':'20px'}}>Key Features</h1>

                <h1 style={{'color':'rgba(152,26,155,1)', 'font-family': 'Gotham'}}> POLITICS IS CHANGING.<br/>
YOUR APPROACH SHOULD, TOO.</h1>

             <h6 style={{'color':'rgba(83,34,84,1)', 'fontSize': '15px','font-family': 'Gotham-Light','fontWeight': '390' ,'marginBottom':'20px',}}>  Canada’s political landscape is being shaped by supporter engagement and digital <br/>campaigns. CSAE Digital Advocacy helps your supporters connect with politicians at the<br/> federal, provincial and municipal level.</h6> 
                
               <h6  style={{'color':'rgba(83,34,84,1)', 'fontSize': '15px','font-family': 'Gotham-Light','fontWeight': '390' , }}> Make an impact with direct engagement.</h6>
               <h6   style={{'color':'rgba(83,34,84,1)', 'fontSize': '15px','font-family': 'Gotham-Light','fontWeight': '390' , }}>   Make noise on social media.</h6>
               <h6  style={{'color':'rgba(83,34,84,1)', 'fontSize': '15px','font-family': 'Gotham-Light','fontWeight': '390' , }}>  Make your campaigns better with supporter data.</h6>
                
              <h6  style={{'color':'rgba(83,34,84,1)','fontSize': '15px','fontWeight': '700' }}>  Make change with CSAE Digital Advocacy.</h6>
                
                        {/* <div className="feature_box">
                        <div className="Feature_seaction_div">
                            <a className="feature_anchor " href="#">
                            <img src="/Assets/images/progress.png" className="feature_images"/>
                                <p className="feature_para">Proudly progressive</p>
                                
                            </a>
                            <a className="feature_anchor color" href="#">
                            <img src="/Assets/images/tour.png" className="feature_images"/>
                                <p className="feature_para">Take a tour</p>
                            </a>
                        </div>
                        <div className="Feature_seaction_div">
                            
                            <a className="feature_anchor color" href="#">
                            <img src="/Assets/images/consulting.png" className="feature_images"/>
                                <p className="feature_para"> Consulting Services</p>
                           
                            </a>
                            <a className="feature_anchor " href="#">
                            
                                <img src="/Assets/images/chart.png" className="feature_images"/>
                                <p className="feature_para">Campaign Activity Analytics</p>
                                
                            </a>
                        </div>
                        </div> */}
            </div></div>
            </section>
        )
    }
}