import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import browserUpdate from 'browser-update';

import AppRoutes from "./Router/Router";

browserUpdate({
    required: {
        e: -2,
        i: 12,
        f: -3,
        o: -3,
        s: 10.1,
        c: "64.0.3282.16817",
        samsung: 7.0,
        vivaldi: 1.2
    },
    insecure: true,
    text: {
        'msg': 'Your web browser ({brow_name}) is out of date.',
        'msgmore': 'Update your browser for more security, speed and the best experience on this site.',
        'bupdate': 'Update browser',
        'bignore': 'Ignore',
        'remind': 'You will be reminded in {days} days.',
        'bnever': 'Never show again'
    },
    reminder: 0,
    unsupported: true,
    api: 2020.05
});

ReactDOM.render(<BrowserRouter>
    <AppRoutes />
</BrowserRouter>, document.getElementById('root'));
