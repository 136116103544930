import React from "react";
import { Redirect } from "react-router-dom";
import Utility from "../common/Utility";
import TopHeader from "../menu/TopHeader";
export default class UpgradePlan extends React.Component {
  constructor() {
    super();
    this.state = {
      plan_title: "",
      subscription: [],
      redirect: false,
      checkout_id: ""
    };
    this.checkout = this.checkout.bind(this);
  }
  componentWillMount() {
    var data = {
      subs_id: this.props.match.params.id,
      clientsubs_id: localStorage.getItem("client_id")
    };
    Utility.ExecuteData("checkSubscriptionForClientSide", data).then(
      responseJson => {
        // console.log(responseJson);
        if (responseJson.code == "200") {
          var list = responseJson.data;
          let newlist = new Array();
          if (list) {
            list.map((v, k) => {
              var newfeature = new Array();
              if (typeof newlist[v.id] != "undefined") {
                newfeature = newlist[v.id].feature;
              }
              newfeature.push(v.title);
              newlist[v.id] = {
                id: v.id,
                title: v.subscriptions,
                price: v.price,
                feature: newfeature
              };
            });

            this.setState({
              subscription: newlist,
              plan_title: responseJson.subs_list[0].title
            });
          }
        }
      }
    );
  }
  checkout(subs_id) {
    this.setState({
      checkout_id: subs_id,
      redirect: true
    });
  }

  render() {
    //console.log(this.state.subscription);
    const { redirect } = this.state;
    if (redirect) {
      return (
        <Redirect
          push
          to={"/checkout/" + this.state.checkout_id + "/upgrade"}
        />
      );
    }
    return (
      <div className="mtop75">
        <TopHeader
          buttonType={this.props.ButtonType}
          getidfromtoken={this.props.getUserInfo}
        />
        <h2 className="pricebox_head">
          you already subscribed for{" "}
          <u class="pricebox_head_bronz">{this.state.plan_title}</u>
        </h2>
        <div className="flex-container pricebox_wapp">
          {this.state.subscription
            ? this.state.subscription.map((val, key) => {
                return (
                  <div className=" pos_rel price_box">
                    <ul className="package">
                      <li className="header padding10">{val.title}</li>
                      <div className="price_overflow">
                        <li>
                          {val.feature.map(i => {
                            return <li className="padding10">{i}</li>;
                          })}
                        </li>
                      </div>
                      <div className="pos_abs_btm">
                        <li className="gray">${val.price}</li>
                        <li className="gray">
                          <button onClick={() => this.checkout(val.id)}>
                            Upgrade Plan
                          </button>
                        </li>
                      </div>
                    </ul>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    );
  }
}
