export const eng = {
  dashboard: "Dashboard",
  log_out: "Log Out",
  log_in: "Log In",
  register: "Register",
  search: "Search",
  clear: "Clear",
  clients: "Clients",
  create_client: "Create Clients",
  update_client: "Update Clients",
  user: "Staff User",
  user_list: "User List",
  create_user: "Create User",
  update_user: "Update User",
  role: "User Roles",
  edit: "Edit",
  delete: "Delete",
  add: "Add",
  update: "Update",
  create: "Create",
  cancel: "Cancel",
  delete_confirm: "Are you sure want to delete?",
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",
  uname: "Username",
  password: "Password",
  conf_password: "Confirm Password",
  contact: "Contact",
  status: "Status",
  active: "Active",
  inactive: "Inactive",
  message_conf_pwd: "Confirm Password Cannot be empty",
  message_pwd_match: "Password and Confirm Password are not matched",
  message_pwd_empty: "Password Cannot be empty",
  message_email_pwd_empty: "Email and Password are Invalid",
  chk_empty_fname: "First Name Cannot be empty",
  chk_empty_uname: "User Name Cannot be empty",
  select_status: "Please select a Status",
  chk_empty_email: "Email Cannot be empty",
  chk_valid_email: "Email is not valid.",
  try_again: "Something went wront. Try Again.",
  title: "Title",
  description: "Description",
  create_role: "Create Role",
  select_title: "Title cannot be empty.",
  subscription: "Subscription",
  subscription_list: "Subscription list",
  duration: "Duration",
  price: "Price",
  select_duration: "Duration cannot be empty.",
  select_price: "Price cannot be empty. ",
  paymentgateway: "Payment Gateway",
  uname: "User name",
  office_number: "Office Number",
  user_designation: "User Designation",
  user_address: "User Address",
  templates: "Templates",
  email_templates: "Email Template",
  password_and_confirm_password_mustbe_same:"Password and confirm password must be same"
};
