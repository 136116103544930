import React from "react";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import PricingTable from "../pricing/PricingTable";
import HomeSlider from "../home/HomeSlider";
import Header from "../menu/TopHeader";
import Footer from "../menu/Footer";
import About from "../about/About";
import Partners from "../partners/Partners";
import Features from "../features/Features";
import SubDomain from "../common/SubDomain";
import Utility from "../common/Utility";
import Widget from "../widget/widget";
import Campaign from "../campaign/Campaign";
import LoginPopUp from "../visit/LoginPopUp";
import SweetAlert4 from "react-bootstrap-sweetalert";

import $ from "jquery";
import SweetAlert2 from "react-bootstrap-sweetalert";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.match.params.token ? true : false,
      showsuccess: false,
      redirect: false,
      loginmodal: false,
      showsuccess3: false,
    };

    this.token = localStorage.getItem("token");
    this.openModal = this.openModal.bind(this);
    this.showSuccessMsg = this.showSuccessMsg.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }
  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentWillMount() {
    console.log(this.props);
    if (this.props.match.params.token) {
      this.verifyEmail(this.props.match.params.token);
    }
  }
  toggle() {
    this.setState({
      loginmodal: !this.state.loginmodal,
    });
  }
  verifyEmail = (token) => {
    // console.log(token)
    fetch(Utility.baseUrl() + "verifyemail?token=" + token, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((responseJson) => {
      this.openModal();
      //console.log(responseJson)
      if (responseJson.status === 200) {
        let ref = this;
        setTimeout(function() {
          ref.closeModal();
          ref.setState({
            showsuccess: true,
            title: "Email successfully  verified.",
          });
        }, 5000);
        localStorage.setItem("verified", 1);
      } else {
        this.setState({
          showsuccess: true,
          title: "Email not verified.",
        });
      }
    });
  };

  showSuccessMsg() {
    var token = localStorage.getItem("token");
    if (token) {
      this.setState({
        showsuccess: false,
        modal: false,
        redirect: true,
      });
    } else {
      this.setState(
        {
          showsuccess: false,
        },
        () => {
          this.toggle();
        }
      );
    }
  }
  getPriceTableData = () => {
    var d = localStorage.getItem("user_data");
    var u_data = {};
    if (d) {
      u_data = JSON.parse(d);
    }
    //console.log("vv  ", u_data.id);
    if (u_data) {
      var data = {
        id: u_data.id,
      };
    } else {
      var data = {
        id: localStorage.getItem("parent_id"),
      };
    }

    Utility.ExecuteData("findclientbyuserid", data).then((res) => {
      if (res) {
        // //consolee.log(res.data);
        localStorage.setItem("client_id", res.data.client_id);
        this.setState({
          client_id: res.data.client_id,
        });
      }
      var client_id;
      var data;
      client_id = localStorage.getItem("client_id");
      var parent_id = localStorage.getItem("parent_id");
      data = {
        client_id: client_id,
        id: u_data.id,
        parent_id: parent_id,
      };
      //consolee.log(data);
      Utility.ExecuteData("subscriptionlistforclit", data).then((res) => {
        //consolee.log(res);
        this.setState(() => ({
          datalist: data ? data.data : "",
        }));

        var list = res.data;
        let newlist = new Array();
        if (list) {
          list.map((v, k) => {
            var newfeature = new Array();
            if (typeof newlist[v.id] != "undefined") {
              newfeature = newlist[v.id].feature;
            }
            newfeature.push(v.title);
            newlist[v.id] = {
              id: v.id,
              title: v.subscriptions,
              price: v.price,
              feature: newfeature,
              status: v.status,
              duration: v.duration,
            };
          });
          newlist.sort(this.GetSortOrder("price"));
          //consolee.log(newlist)
          this.setState({
            subscription: newlist,
          });
          newlist.map((i) => {
            if (i.status == "active") {
              this.setState({
                purchased_price: i.price,
                purchased_id: i.id,
                subscription: newlist,
                duration: i.duration,
              });
            }
          });
        }
      });
    });
  };
  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("myBtn").style.display = "block";
    } else {
      document.getElementById("myBtn").style.display = "none";
    }
  }
  logout(name) {
    // console.log(name)
    if (name) {
      this.setState({
        showsuccess3: true,
      });
    } else {
      this.setState({
        showsuccess3: true,
      });
    }
  }

  GetSortOrder = (prop) => {
    return function(a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };
  render() {
    const { redirect } = this.state;

    //  console.log(this.state.loginmodal)

    if (redirect) {
      return <Redirect push to="/" />;
    }
    return (
      <div>
        <Header
          buttonType={this.props.ButtonType}
          getidfromtoken={this.props.getUserInfo}
          loginType={this.props.loginType}
          clientID={this.props.client_id}
          userType={this.props.userType}
          param_token={
            this.props.match.params.token ? this.props.match.params.token : ""
          }
        />
        {/* <SubDomain></SubDomain> */}
        <div
          style={{ width: "100%", marginTop: "100px", position: "relative" }}
        >
          {window.location.host == "localhost" ||
          window.location.host.indexOf("csaeconnect.ca") != -1 ? (
            <div
              className="new_overlay"
              style={{
                position: "absolute",
                top: "10%",
                left: "6%",
                color: "rgb(255, 255, 255)",
                fontSize: "20px",
                background: "rgba(0,0,0,0.2)",
                padding: "10px",
              }}
            >
              <b>Mobilize Supporters.</b>
              <br />
              <b>Reach Decisionmakers.</b>
              <br />
              <b>Get Results.</b>
              <p style={{ fontSize: "18px" }}>
                Our award-winning platform is built for Canadian association
                advocacy.
                <br />
                Our focus is connecting your supporters with government and
                amplifying your voice.
                <br />
                Make an impact and measure results with our suite of tools.
              </p>
            </div>
          ) : (
            ""
          )}
          <img
            src={"/Assets/images/pics2.jpg"}
            className={"img img-responsive"}
            style={{ width: "100%" }}
          />
        </div>
        {/* <HomeSlider /> */}
        {this.props.match.params.token ? (
          // <VerifyingEmailPop id="test"
          //   isOpen={this.state.modal}
          //   toggle={this.toggle}
          //   verifying_token={this.props.match.params.token}

          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
          >
            <ModalHeader>
              <div className="all_center">
                <p className="verify_text">
                  Please wait! we are verifying your Email
                </p>
                <img src="/Assets/images/email.png" className="email_icon" />
              </div>
            </ModalHeader>
          </Modal>
        ) : (
          ""
        )}
        <Features />
        <SweetAlert2
          success
          title={this.state.title}
          show={this.state.showsuccess}
          onConfirm={this.showSuccessMsg}
        />
        <Campaign />
        <div className="main_container_body main_container_bodypricing">
          <PricingTable
            key={"price"}
            buttonType={this.props.ButtonType}
            getuser={this.props.getUserInfo}
            user_type={this.props.userType}
          />
        </div>
        {/* <Widget /> */}

        <div className="main_container_body">{/* <Partners /> */}</div>
        {/* <Footer /> */}
        <About />
        <SweetAlert4
          success
          title="Login Successfully!"
          show={this.state.showsuccess3}
          onConfirm={this.showSuccessMsg}
        />
        <LoginPopUp
          isOpen={this.state.loginmodal}
          getid={this.props.getUserInfo}
          toggle={this.toggle}
          chckout={this.state.checkoutID}

          // getPriceTableData={this.getPriceTableData()}
        />
      </div>
    );
  }
}
