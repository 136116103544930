const content = {
  "www.pushpolitics.org": {
    title: "Push Politics Ca",
    logo: "https://www.pushpolitics.org/Assets/images/pp33.png",
    description: "",
    about: " We have developed a platform with busy association executives in mind, exclusively for the Canadian marketplace. CSAE Digital Advocacy was designed for the Canadian political system with full compliance for Canadian laws and regulations. All supporter data is housed on Canadian servers with industry leading security in place."
  },
  "www.csaeconnect.ca": {
    title: "CSAE",
    logo: "https://www.csaeconnect.ca/DAcsaeDarkWHITE.png",
    description: "",
    about: " We have developed a platform with busy association executives in mind, exclusively for the Canadian marketplace. CSAE Digital Advocacy was designed for the Canadian political system with full compliance for Canadian laws and regulations. All supporter data is housed on Canadian servers with industry leading security in place."
  },
  "localhost": {
    title: "CSAE",
    overlay: "",
    logo: "https://www.csaeconnect.ca/DAcsaeDarkWHITE.png",
    description: "",
    about: " We have developed a platform with busy association executives in mind, exclusively for the Canadian marketplace. CSAE Digital Advocacy was designed for the Canadian political system with full compliance for Canadian laws and regulations. All supporter data is housed on Canadian servers with industry leading security in place."
  },
  "pushpolitics.org": {
    title: "Push Politics Ca",
    logo: "https://www.pushpolitics.org/Assets/images/pp33.png",
    description: "",
    about: " We have developed a platform with busy association executives in mind, exclusively for the Canadian marketplace. CSAE Digital Advocacy was designed for the Canadian political system with full compliance for Canadian laws and regulations. All supporter data is housed on Canadian servers with industry leading security in place."
  },
  "csaeconnect.ca": {
    title: "CSAE",
    logo: "https://www.csaeconnect.ca/DAcsaeDarkWHITE.png",
    description: "",
    about: " We have developed a platform with busy association executives in mind, exclusively for the Canadian marketplace. CSAE Digital Advocacy was designed for the Canadian political system with full compliance for Canadian laws and regulations. All supporter data is housed on Canadian servers with industry leading security in place."
  },
  "localhost:3000": {
    title: "CSAE",
    logo: "./DAcsaeDarkWHITE.png",
    description: "",
    about: " We have developed a platform with busy association executives in mind, exclusively for the Canadian marketplace. CSAE Digital Advocacy was designed for the Canadian political system with full compliance for Canadian laws and regulations. All supporter data is housed on Canadian servers with industry leading security in place."

  }
};
export default content;
