import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import classnames from "classnames";
import Utility from "../common/Utility";
import ForgotPassword from "../visit/ForgotPasswordPopUp";
import { __t } from "../../local/Language";
import bcrypt from "bcryptjs";
import SweetAlert4 from "react-bootstrap-sweetalert";
// import Swal from "sweetalert2"; // Import SweetAlert library

import "./CustomSweetAlert.css";

class LoginPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      errors: {},
      forgot_modal: false,
      lang: "en",
      activeTab: "1",
      enable: "true",
      domain: "",
      showsuccess3: false,
      show: false,

      is2FAEnabled: false,
      otp: "",
      showOTPField: false,
      showIncorrectOTP: false,
      no2FAMsg: false,
      resultData: {},

      loginData: null,
      loginToken: "",

      descriptions: [],
      selectedDescription: "",
      defaultAuth: "",

      emptyOTP: false,
      emptyDescription: false,

      userID: null,
      loginAttempts: 0,
      accountLocked: false,
      accountLockedMessage: false,
    };
    this.login = this.login.bind(this);
    this.forgot_toggle = this.forgot_toggle.bind(this);
    this.toggletab = this.toggletab.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.Signin = this.Signin.bind(this);
    this.SignhandleChange = this.handleChange.bind(this);
    this.createHash = this.createHash.bind(this);
    this.showRegisterMsg3 = this.showRegisterMsg3.bind(this);
    this.check2FA = this.check2FA.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.showIncorrectOTPMessage = this.showIncorrectOTPMessage.bind(this);
    this.showNo2FAMsg = this.showNo2FAMsg.bind(this);
    this.emptyOTPMessage = this.emptyOTPMessage.bind(this);
    this.emptyDescriptionMessage = this.emptyDescriptionMessage.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.getTwoFAConfig = this.getTwoFAConfig.bind(this);
    this.accountLockedMessageHide = this.accountLockedMessageHide.bind(this);

    localStorage.setItem("language", "English");
  }

  redirectToUrl() {
    let token = localStorage.getItem("token");
    if (token) {
      let queryParams = `user/update/${this.state.userID}`;
      // const temp = "https://admin.pushpolitics.org/";
      // const temp = "http://localhost:8000/";
      // let url = temp + `login/` + token + `?redirect=${queryParams}`;
      let url =
        Utility.adminUrl() + `login/` + token + `?redirect=${queryParams}`;
      window.location.href = url;
    } else {
      window.location.href = "";
    }
  }

  async getTwoFAConfig() {
    try {
      const response = await fetch(Utility.baseUrl() + "get2faconfig", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({
          resultData: data.data,
        });
      } else {
        console.log("Failed to fetch 2FA config");
      }
    } catch (error) {
      console.log(error);
    }
  }

  fetchData(userId) {
    const payload = {
      userId: userId,
    };

    try {
      fetch(Utility.baseUrl() + "2fa_getData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch descriptions");
          }
          return response.json();
        })
        .then((data) => {
          this.setState({
            descriptions: data.descriptions.map((record) => record.description),
            defaultAuth: data.descriptions.map(
              (record) => record.defaultAuth
            )[0],
            selectedDescription: data.descriptions.map(
              (record) => record.defaultAuth
            )[0],
          });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  // API Call to check if 2FA is enabled
  check2FA(userId) {
    return new Promise((resolve, reject) => {
      const url = Utility.baseUrl() + "2fa_check";
      const payload = {
        id: userId,
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (response.status === 403) {
            throw new Error("Forbidden");
          }
          return response.json();
        })
        .then((data) => {
          this.setState({
            is2FAEnabled: data.enabled,
            showOTPField: data.enabled,
          });

          if (!data.enabled) {
            this.setState({
              no2FAMsg: true,
            });
          }
          resolve(); // Resolve the promise after successful execution
        })
        .catch((error) => {
          console.log(error);
          reject(error); // Reject the promise if there's an error
        });
    });
  }

  // API call to verify OTP
  handleVerify() {
    const otp = this.state.otp; // Get the OTP entered by the user
    const currentDate = new Date();
    const description =
      this.state.descriptions.length === 1
        ? this.state.descriptions[0]
        : this.state.selectedDescription;
    let emptyOTP = false;
    let emptyDescription = false;

    if (otp === "") {
      emptyOTP = true;
    }

    if (description === "") {
      emptyDescription = true;
    }

    if (emptyOTP || emptyDescription) {
      this.setState({
        emptyOTP,
        emptyDescription,
      });
      return; // Exit the function if either OTP or description is empty
    }

    // Make a call to 2fa_verify API
    fetch(Utility.baseUrl() + "2fa_verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.loginToken,
      },
      body: JSON.stringify({ otp, lastUpdated: currentDate, description }), // Pass the OTP as part of the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log(`OTP Verification successful`);
          localStorage.setItem("token", this.state.loginToken);
          localStorage.setItem(
            "verified",
            this.state.loginData.user_data.verified
          );
          localStorage.setItem(
            "user_data",
            JSON.stringify(this.state.loginData.user_data)
          );
          this.setState({
            message: "Login Successfully",
          });
          this.props.getid();
          this.props.toggle();
        } else {
          this.setState({
            showIncorrectOTP: true,
          });
          console.log(`OTP Verification failed`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggletab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  showRegisterMsg3() {
    this.setState({
      showsuccess3: false,
    });
  }
  showIncorrectOTPMessage() {
    this.setState({
      showIncorrectOTP: false,
    });
  }
  emptyOTPMessage() {
    this.setState({
      emptyOTP: false,
    });
  }
  emptyDescriptionMessage() {
    this.setState({
      emptyDescription: false,
    });
  }
  accountLockedMessageHide() {
    this.setState({
      accountLockedMessage: false,
    });
  }
  showNo2FAMsg() {
    this.setState({
      no2FAMsg: false,
    });
    this.redirectToUrl();
    this.props.toggle();
  }

  forgot_toggle() {
    this.props.toggle();
    this.setState({
      forgot_modal: !this.state.forgot_modal,
    });
  }

  changeLanguage = (event) => {
    localStorage.setItem("language", event.target.value);
    this.setState({ lang: event.target.value });
  };
  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (fields.errors === "not valid") {
      formIsValid = false;
      errors["password_login"] = __t("message_email_pwd_empty");
    }

    if (!fields["password_login"]) {
      formIsValid = false;
      errors["password_login"] = __t("message_pwd_empty");
    }

    //Email
    if (typeof fields["email_login"] !== "undefined") {
      let lastAtPos = fields["email_login"].lastIndexOf("@");
      let lastDotPos = fields["email_login"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email_login"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email_login"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email_login"] = __t("chk_valid_email");
      }
    }
    if (!fields["email_login"]) {
      formIsValid = false;
      errors["email_login"] = __t("chk_empty_email");
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  login() {
    let ref = this;
    var pr_id = localStorage.getItem("parent_id");
    var url = window.location.href;
    url = url.split("/");
    var subDomain = url[2];
    if (window.location.href.indexOf("localhost") != -1) {
      subDomain = "www.pushpolitics.org";
    }
    // var subDomain = "www.csaeconnect.ca";
    //  console.log(subDomain)
    const email = this.state.email_login;
    const accountLocked =
      localStorage.getItem(`accountLocked_${email}`) === "true";
    const lockTimestamp = parseInt(
      localStorage.getItem(`accountLockTimestamp_${email}`),
      10
    );
    const lockDuration = 30 * 60 * 1000; // 1 minute in milliseconds
    const currentTime = Date.now();

    if (accountLocked && currentTime - lockTimestamp < lockDuration) {
      // If account is already locked, show the account locked popup
      this.setState({ accountLockedMessage: true });
      return;
    }

    if (this.handleValidation()) {
      fetch(Utility.baseUrl() + "clientlogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.email_login,
          pass: this.state.password_login,
          // parent_id: pr_id,
          // domain:this.state.domain
          domain: subDomain,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(this, 'this');
          if (responseJson.code === 200) {
            const userId = responseJson.user_data.id;
            this.setState({
              userID: userId,
            });

            // console.log(this.state.resultData, 'responseJson');
            if (
              this.state.resultData &&
              this.state.resultData.enable_user == "1"
            ) {
              this.check2FA(userId)
                .then(() => {
                  this.setState({
                    loginToken: responseJson.token,
                    loginData: responseJson,
                  });
                  this.fetchData(userId);

                  if (!this.state.is2FAEnabled) {
                    localStorage.setItem("token", this.state.loginToken);
                    localStorage.setItem(
                      "verified",
                      this.state.loginData.user_data.verified
                    );
                    localStorage.setItem(
                      "user_data",
                      JSON.stringify(this.state.loginData.user_data)
                    );
                    this.setState({
                      message: "Login Successfully",
                    });
                    this.props.getid();
                  }
                })
                .catch((error) => {
                  // Handle error from check2FA function
                });
            } else {
              
              this.setState({
                loginToken: responseJson.token,
                loginData: responseJson,
              });
              console.log(responseJson, "responseJson");
              localStorage.setItem("token", this.state.loginToken);
              localStorage.setItem(
                "verified",
                this.state.loginData.user_data.verified
              );
              localStorage.setItem(
                "user_data",
                JSON.stringify(this.state.loginData.user_data)
              );
              this.setState({
                message: "Login Successfully",
              });
              this.props.getid();
              this.props.toggle();
            }
          } else if (responseJson.code === 402) {
            this.setState({
              showsuccess3: true,
            });
          } else if (responseJson.code === 204) {
            // Handle invalid login
            this.setState((prevState) => ({
              loginAttempts: prevState.loginAttempts + 1,
            }));

            if (this.state.loginAttempts >= 3) {
              // Lock the account for 30 minutes
              localStorage.setItem(`accountLocked_${email}`, "true");
              localStorage.setItem(`accountLockTimestamp_${email}`, Date.now());
              this.setState({
                accountLockedMessage: true,
              });

              setTimeout(() => {
                localStorage.removeItem(`accountLocked_${email}`);
                localStorage.removeItem(`accountLockTimestamp_${email}`);
                this.setState({
                  loginAttempts: 0,
                  accountLockedMessage: false,
                });
              }, 30 * 60 * 1000);
            } else {
              this.handleValidation(this.setState({ errors: "not valid" }));
            }
          }
        })
        .catch((error) => {
          this.setState({ errors: true });
        });
    } else {
      // Handle validation errors
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  createHash(pwd) {
    const saltRounds = 10;
    return new Promise((resolve, reject) => {
      bcrypt.genSalt(saltRounds, function(err, salt) {
        if (err) return err;
        bcrypt.hash(pwd, salt, function(err, hash) {
          // Store hash in your password DB.
          if (err) {
            reject(err);
          } else {
            resolve(hash);
          }
        });
      });
    });
  }

  componentDidMount() {
    this.getTwoFAConfig();
    var url = window.location.href;
    url = url.split("/");
    var subDomain = url[2];
    this.setState({ domain: subDomain });
  }
  SignhandleValidation() {
    var url = window.location.href;
    url = url.split("/");
    var subDomain = url[2];
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (fields.errors === "not valid") {
      formIsValid = false;
      errors["password"] = __t("message_email_pwd_empty");
    }

    if (!fields["firstname"]) {
      formIsValid = false;
      errors["firstname"] = __t("Please enter first name");
    }
    if (!fields["lastname"]) {
      formIsValid = false;
      errors["lastname"] = __t("Please enter last name");
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = __t("Password can not be empty");
    }
    if (fields["password"] !== fields["confirmpassword"]) {
      formIsValid = false;
      errors["password"] = __t("password_and_confirm_password_mustbe_same");
    }

    //Email
    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = __t("chk_valid_email");
      }
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = __t("chk_empty_email");
    }

    this.setState({
      errors: errors,
      domain: subDomain,
    });
    return formIsValid;
  }

  Signin() {
    if (this.SignhandleValidation()) {
      if (this.state.password) {
        // for client update when password is change
        // this.createHash(this.state.password).then(myhash => {
        let pid = localStorage.getItem("parent_id");
        fetch(Utility.baseUrl() + "signup", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.state.email,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            password: this.state.password,
            domain: this.state.domain,
            parent_id: pid,
          }),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            var u_data = JSON.stringify(responseJson.user_data);
            if (responseJson.code === 200) {
              localStorage.setItem("token", responseJson.token);
              localStorage.setItem("user_data", u_data);
              localStorage.setItem("verified", "0");
              if (responseJson.client_id) {
                localStorage.setItem("client_id", responseJson.client_id);
              }
              this.props.getid();
              this.props.toggle("Successfully Registered", this.props.chckout);
              this.props.showRegisterMsg();
            } else if (responseJson.code === 204) {
              alert(responseJson.success);
            }
          })
          .catch((error) => {
            this.setState({ errors: true });
          });
        // });
      }
    } else {
    }
  }
  SignhandleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  componentWillReceiveProps() {
    this.setState({
      errors: {},
    });
  }

  handleDropdownChange = (event) => {
    this.setState({ selectedDescription: event.target.value });
  };

  render() {
    const accountLocked = localStorage.getItem("accountLocked") === "true";
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          zIndex={9999}
          toggle={
            this.state.is2FAEnabled && this.state.showOTPField
              ? ""
              : this.props.toggle
          }
          className={this.props.className}
        >
          {this.state.is2FAEnabled && this.state.showOTPField ? (
            <ModalHeader className="center100">LogIn/SignUp</ModalHeader>
          ) : (
            <ModalHeader toggle={this.props.toggle} className="center100">
              LogIn/SignUp
            </ModalHeader>
          )}
          <ModalBody>
            {/* Render OTP field only if showOTPField is true */}
            {this.state.is2FAEnabled ? (
              this.state.showOTPField && (
                <div className="form-group pos_rel">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "20px",
                    }}
                  >
                    {this.state.descriptions.length === 1 ? (
                      <div>{this.state.descriptions[0]}</div>
                    ) : (
                      <select
                        value={this.state.selectedDescription}
                        onChange={this.handleDropdownChange}
                        style={{
                          width: "200px",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                          backgroundColor: "#f9f9f9",
                          textAlign: "center",
                          appearance: "none",
                          outline: "none",
                          fontWeight: "bold",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <option value="">Select Authenticator</option>
                        {this.state.descriptions.map((description, index) => (
                          <option
                            key={index}
                            value={description}
                            style={{
                              textAlign: "center",
                            }}
                            selected={description === this.state.defaultAuth}
                          >
                            {this.state.defaultAuth === description
                              ? `${description} (Default)`
                              : description}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>

                  <input
                    type="text"
                    name="otp"
                    className="form-control"
                    placeholder="Enter OTP"
                    style={{ textAlign: "center" }}
                    required
                    onChange={this.handleChange}
                  />

                  {/* Render the "Verify" button */}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      type="button"
                      className="btn btn_orange block full-width m-b"
                      onClick={this.handleVerify}
                      style={{ marginTop: "20px" }}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              )
            ) : (
              <>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggletab("1");
                      }}
                    >
                      LogIn
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggletab("2");
                      }}
                    >
                      SignUp
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="form-group pos_rel">
                      <img
                        src="/Assets/images/envelope.png"
                        className="input_img"
                      />
                      <input
                        type="email"
                        name="email_login"
                        className="form-control paddingleft40"
                        placeholder="Email"
                        required
                        onChange={this.handleChange}
                      />
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["email_login"]}
                    </span>
                    <div className="form-group pos_rel">
                      <img
                        src="/Assets/images/lock.png"
                        className="input_img"
                      />
                      <input
                        type="password"
                        name="password_login"
                        className="form-control paddingleft40"
                        placeholder="Password"
                        required=""
                        onChange={this.handleChange}
                      />
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["password_login"]}
                    </span>
                    <div className="flx_align_center">
                      <button
                        type="submit"
                        className="btn btn_orange block full-width m-b"
                        onClick={() => {
                          this.login();
                        }}
                      >
                        Login
                      </button>
                    </div>
                    <div className="flx_align_center">
                      <span className="flt_right" onClick={this.forgot_toggle}>
                        <small className="text_orange">Forgot password?</small>
                      </span>
                    </div>
                  </TabPane>
                </TabContent>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="2">
                    <div className="form-group pos_rel">
                      <img
                        src="/Assets/images/name.png"
                        className="input_img"
                      />
                      <input
                        type="text"
                        name="firstname"
                        className="form-control paddingleft40"
                        placeholder="First Name"
                        required
                        onChange={this.SignhandleChange}
                      />
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["firstname"]}
                    </span>
                    <div className="form-group pos_rel">
                      <img
                        src="/Assets/images/name.png"
                        className="input_img"
                      />
                      <input
                        type="text"
                        name="lastname"
                        className="form-control paddingleft40"
                        placeholder="Last Name"
                        required
                        onChange={this.SignhandleChange}
                      />
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["lastname"]}
                    </span>

                    <div className="form-group pos_rel">
                      <img
                        src="/Assets/images/envelope.png"
                        className="input_img"
                      />
                      <input
                        type="email"
                        name="email"
                        className="form-control paddingleft40"
                        placeholder="Email"
                        required
                        onChange={this.SignhandleChange}
                      />
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["email"]}
                    </span>
                    <div className="form-group pos_rel">
                      <img
                        src="/Assets/images/lock.png"
                        className="input_img"
                      />
                      <input
                        type="password"
                        name="password"
                        className="form-control paddingleft40"
                        placeholder="Password"
                        required=""
                        onChange={this.SignhandleChange}
                        value={this.state.password}
                      />
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["password"]}
                    </span>
                    <div className="form-group pos_rel">
                      <img
                        src="/Assets/images/lock.png"
                        className="input_img"
                      />
                      <input
                        type="password"
                        name="confirmpassword"
                        className="form-control paddingleft40"
                        placeholder="Confirm Password"
                        required=""
                        data-match="#password"
                        data-match-error="Whoops, these don't match"
                        onChange={this.SignhandleChange}
                      />
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["confirmpassword"]}
                    </span>
                    <div className="form-group" />
                    <div className="flx_align_center">
                      <button
                        type="submit"
                        className="btn btn_orange block full-width m-b"
                        onClick={() => {
                          this.Signin();
                        }}
                      >
                        SignUp
                      </button>
                    </div>
                    <div className="flx_align_center mtop_10">
                      {/* <small>
                    {" "}
                    Have already an account ?{" "}
                    <span className="text_orange"> Login here</span>
                  </small> */}
                    </div>
                  </TabPane>
                </TabContent>
              </>
            )}
          </ModalBody>
        </Modal>
        <ForgotPassword
          isOpenforgotPass={this.state.forgot_modal}
          Forgottoggle={this.forgot_toggle}
        />
        <SweetAlert4
          type="warning"
          title="Domain not matched!"
          show={this.state.showsuccess3}
          onConfirm={this.showRegisterMsg3}
        />
        <SweetAlert4
          type="warning"
          title="Incorrect One-Time Password"
          show={this.state.showIncorrectOTP}
          onConfirm={this.showIncorrectOTPMessage}
        />
        <SweetAlert4
          type="warning"
          title="Please enter One-Time Password"
          show={this.state.emptyOTP}
          onConfirm={this.emptyOTPMessage}
        />
        <SweetAlert4
          type="warning"
          title="Please select Authenticator App"
          show={this.state.emptyDescription}
          onConfirm={this.emptyDescriptionMessage}
        />
        <SweetAlert4
          type="warning"
          title="Account Locked"
          show={this.state.accountLockedMessage}
          onConfirm={this.accountLockedMessageHide}
        >
          Due to multiple invalid attempts, you account is locked and you are
          requested to wait for 30 minutes to login again
        </SweetAlert4>
        <SweetAlert4
          type="warning"
          title="Two Factor Authentication Not Enabled"
          html={true}
          show={this.state.no2FAMsg}
          onConfirm={this.showNo2FAMsg}
        >
          Please be informed that the administrator has enabled Two-Factor
          Authentication (2FA) for your account. For enhanced security, it is
          necessary for you to set it up from your account settings. Please
          proceed with the 2FA setup by clicking on the Ok button.
        </SweetAlert4>
      </div>
    );
  }
}

export default LoginPopUp;
