import React from "react";
import $ from "jquery";

class Utility extends React.Component {
  constructor(props, context) {
    super(props);
    this.token = localStorage.getItem("token");
  }

  PostData = (action, type) => {
    return fetch(this.baseUrl() + action, { method: type })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  GetData = (action, token) => {
    $(".gray-bg").addClass("sk-loading");
    let actkn = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      fetch(this.baseUrlBe() + action, {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + actkn,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          $(".gray-bg").removeClass("sk-loading");
          if (typeof responseJson.code !== "undefined") {
            if (responseJson.code == 403) {
              localStorage.clear();
              //resolve(responseJson);
              window.location.reload();
            } else {
              resolve(responseJson);
            }
          } else {
            $(".gray-bg").removeClass("sk-loading");
            window.alert("Internet connection err");
          }
        })
        .catch((error) => {
          $(".gray-bg").removeClass("sk-loading");
          window.alert("Internet Connection Error.");
          console.error(error);
        });
    }).catch((error) => {
      console.error(error);
    });
  };

  ExecuteData = (action, data) => {
    return new Promise((resolve, reject) => {
      fetch(this.baseUrl() + action, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
        })
        .catch((error) => {
          //console.error(error);
        });
    }).catch((error) => {
      // console.error(error);
    });
  };

  async executeRequest(act, req, actkn) {
    actkn = typeof actkn != "undefined" ? "Bearer " + actkn : "";
    let head = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      authorization: actkn,
    };
    let promise = new Promise((resolve, reject) => {
      fetch(this.baseUrl() + act, {
        method: "POST",
        headers: head,
        body: JSON.stringify(req),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
        })
        .catch((error) => {
          reject();
        });
    }).catch((error) => {
      //console.error(error);
    });
    let result = await promise;
    return result;
  }
  //function for capitaize first lette
  capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  backendUrl = () => {
    var backendurl = "https://api.pushpolitics.org/v1/";
    return backendurl;
  };
  // set base url
  baseUrl = () => {
    // var baseurl = "http://localhost:5000/v1/";
    var baseurl = "https://api.pushpolitics.org/v1/";
    return baseurl;
  };
  adminUrl = () => {
    var host = window.location.hostname;
    var adminUrl =
      window.location.protocol + "//" + host.replace("www", "admin") + "/";
    //var adminUrl = "https://admin.pushpolitics.org/";
    return adminUrl;
  };
  getIdFromToken() {
    return JSON.parse(localStorage.getItem("user_data"));
  }

  domain = ".pushpolitics.ca";
}
export default new Utility();
