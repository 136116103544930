import React from "react";
import { Redirect } from "react-router-dom";
import Utility from "../common/Utility";
import TopHeader from "../menu/TopHeader";
import PaypalExpress from "../checkout/PaypalButton";
import Payment_Success from "../checkout/payment_success";
import PaypalButton from "../checkout/PaypalButton";
import paypal from "paypal-checkout";
export default class RenewPlan extends React.Component {
  constructor() {
    super();
    this.state = {
      id: "",
      subscription: [],
      Button_Type: "",
      status: "started",
      subscription_plan: "",
      title: "",
      feature: "",
      price: "",
      subsid: "",
      payment_status: "",
      order_id: "",
      payment: [],
      payment_id: "",
      payer_id: "",
      payment_token: "",
      redirect: false,
      subDomain: "",
      duration: "",
      redirectToUpgrade: false,
      client_id:localStorage.getItem("client_id")
    };
    this.getSubscriptionData = this.getSubscriptionData.bind(this);
    this.getOrder = this.getOrder.bind(this);
    this.insertOrderTransaction = this.insertOrderTransaction.bind(this);
    this.insertClient = this.insertClient.bind(this);
    //  this.checkout_id= this.props.match.params.id
  }

  componentWillMount() {
  this.setState({
      id:this.props.match.params.userid,
      subsid:this.props.match.params.id
  })
  this.getSubscriptionData(this.props.match.params.id);
    // var data = {
    //   subs_id: this.props.match.params.id,
    //   clientsubs_id: localStorage.getItem("client_id")
    // };
    // Utility.ExecuteData("checkSubscriptionForClientSide", data).then(
    //   responseJson => {
    //     console.log(responseJson);
    //     if (responseJson.code == "200") {
    //       if (responseJson.message) {
    //         this.getSubscriptionData(this.props.match.params.id);
    //       } else {
    //         this.setState({
    //           redirectToUpgrade: true
    //         });
    //       }
    //     } else {
    //       this.getSubscriptionData(this.props.match.params.id);
    //     }
    //   }
    // );
  }
  getSubscriptionData(id) {
    fetch(Utility.baseUrl() + "subscriptiondetailsforclient?id=" + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          var list = responseJson.data;
         // console.log(list);
          let newlist = new Array();
          list.map((v, k) => {
            var newfeature = new Array();
            if (typeof newlist[v.id] != "undefined") {
              newfeature = newlist[v.id].feature;
            }
            newfeature.push(v.title);
            newlist[v.id] = {
              title: v.subscriptions,
              price: v.price,
              feature: newfeature
            };
          });
          var my_array = newlist.filter(function(x) {
            return x !== (undefined || null || "");
          });
          this.setState({
            subscription: my_array,
            id: this.props.id,
            subsid: this.props.match.params.id,
            duration: list[0].duration
          });

          //  this.getOrder();
          var feature = [];
          this.state.subscription.map(i => {
            this.setState({
              price: i.price,
              title: i.title
            });
            feature.push(i.feature);
          });
          this.setState({
            feature: feature
          });
        } else if (responseJson.code === 204) {
        }
      });
  }
  getOrder() {
    var feature = [];
    this.state.subscription.map(i => {
      this.setState({
        price: i.price,
        title: i.title
      });
      feature.push(i.feature);
    });
    this.setState({
      feature: feature
    });

    fetch(Utility.baseUrl() + "getorder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state)
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          this.setState({
            order_id: responseJson.id
          });
        } else if (responseJson.code === 204) {
        }
      })
      .catch(error => {
        this.setState({ errors: true });
      });
  }
  insertOrderTransaction() {
    fetch(Utility.baseUrl() + "TransactionforCancel", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state)
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
        } else if (responseJson.code === 204) {
        }
      })
      .catch(error => {
        this.setState({ errors: true });
      });
  }
  insertClient(status) {
    fetch(Utility.baseUrl() + status, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state)
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          this.setState({
            redirect: true
          });
        } else if (responseJson.code === 204) {
        }
      })
      .catch(error => {
        this.setState({ errors: true });
      });
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/payment_success" />;
    }
    const { redirectToUpgrade } = this.state;
    if (redirectToUpgrade) {
      return (
        <Redirect push to={"/upgradePlan/" + this.props.match.params.id} />
      );
    }
    var price;
    const onSuccess = payment => {
      //console.log("The payment was succeeded!", payment);
      if (this.props.match.params.up == "upgrade") {
        this.insertClient("insertClientSubscription");
      } else {
        this.insertClient("clientcreateafterpayment");
      }
    };
    const onCancel = data => {
     // console.log("The payment was cancelled!", data);
      this.setState({
        payment: data,
        payment_id: data.paymentID,
        payment_token: data.paymentToken,
        payment_status: "Cancel"
      });
      this.insertOrderTransaction();
    };

    const onError = err => {
     // console.log("Error!", err);
      this.setState({
        payment: data,
        payment_id: data.paymentID,
        payment_token: data.paymentToken,
        payment_status: "Failed"
      });
      this.insertOrderTransaction();
    };
    let env = "sandbox";

    const data = {
      userid: this.state.id,
      orderid: this.state.order_id,
      subsid: this.state.subsid,
      status: "started",
      feature: this.state.feature,
      subscription_list: this.state.subscription
    };

    const { showButton } = this.state;
    const payment = function() {
      var CREATE_URL = Utility.baseUrl() + "createPayment";
      return paypal.request.post(CREATE_URL, data).then(function(res) {
        return res.id;
      });
    };

    const onAuthorize = function(data, actions) {
      var EXECUTE_URL = Utility.baseUrl() + "executePayment";
      return paypal.request.post(EXECUTE_URL, data).then(function(res) {
        onSuccess(res.body);
      });
    };
    return (
      <div>
        <TopHeader
          buttonType={this.props.ButtonType}
          getidfromtoken={this.props.getUserInfo}
        />

        <div className="checkout_table_box">
          <div className="checkout_btn">
            <h4>YOUR SHOPPING CART</h4>
          </div>
          <table className="table table-border checkout_tbl">
            <thead>
              <tr className="checkout_tbl_td">
                <th>Item</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody className="checkout_body">
              {this.state.subscription
                ? this.state.subscription.map(i => {
                    price = i.price;
                    return (
                      <tr>
                        <td>{i.title}</td>
                        <td>features - {i.feature}</td>
                        <td>{i.price}</td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
          <div className="flx_end">
            <label>{"Total Price:" + price}</label>
          </div>
          <div className="flx_end">
            <PaypalExpress
              env={env}
              onSuccess={onSuccess}
              payment={payment}
              commit={true}
              onAuthorize={onAuthorize}
              onError={onError}
              onCancel={onCancel}
            />
          </div>
        </div>
      </div>
    );
  }
}
