import React from "react";
import { Redirect } from "react-router-dom";
import Utility from "../common/Utility";
import LoginPopUp from "../visit/LoginPopUp";
import SweetAlert from "react-bootstrap-sweetalert";

export default class PricingTable extends React.Component {
  constructor() {
    super();
    this.state = {
      datalist: [],
      total: 0,
      subscription: [],
      modal: false,
      user_type: "",
      first_name: "",
      last_name: "",
      redirect: false,
      checkout_id: "",
      client_id: "",
      purchased_price: "",
      purchased_id: "",
      user_id: "",
      showsuccess: false,
      client_subs: "",
    };
    this.getPriceTableData = this.getPriceTableData.bind(this);
    this.token = localStorage.getItem("token");
    this.toggle = this.toggle.bind(this);
    this.checkSearchDomain = this.checkSearchDomain.bind(this);
    this.GetSortOrder = this.GetSortOrder.bind(this);
    this.showRegisterMsg = this.showRegisterMsg.bind(this);
  }
  toggle(msg, id) {
    //console.log("tttttt,  ", msg, id);
    this.setState({
      checkoutID: id,
    });
    if (msg) {
      if (id) {
        this.setState({
          modal: !this.state.modal,
          redirect: true,
          checkout_id: id,
        });
      } else {
        this.setState({
          modal: !this.state.modal,
        });
      }
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  }
  showRegisterMsg() {
    this.setState({
      showsuccess: false,
    });
  }
  componentWillMount() {
    this.getPriceTableData();
  }
  componentDidMount() {
    this.getPriceTableData();
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops) {
      this.getPriceTableData();
    }
  }

  getPriceTableData() {
    var url = window.location.href;
    url = url.split("/");
    //  var subDomain = url[2];
    var subDomain = "www.csaeconnect.ca";
    //  console.log(subDomain)
    var data;
    if (localStorage.getItem("token")) {
      data = {
        domain: subDomain,
        token: localStorage.getItem("token"),
      };
    } else {
      data = {
        domain: subDomain,
      };
    }
    Utility.ExecuteData("subslistforclient", data).then((res) => {
      if (res) {
        //  console.log(res)
        var list = res.data;
        let newlist = new Array();
        var client_subs = res.client_subs;
        // console.log(client_subs)
        if (list) {
          list.map((v, k) => {
            var newfeature = new Array();
            if (typeof newlist[v.id] != "undefined") {
              newfeature = newlist[v.id].feature;
            }
            newfeature.push(v.title);
            newlist[v.id] = {
              id: v.id,
              title: v.subscriptions,
              price: v.price,
              feature: newfeature,
              status: v.status,
              duration: v.duration,
              description: v.description,
            };
          });
          newlist.sort(this.GetSortOrder("price"));
          //  console.log(newlist)
          this.setState({
            subscription: newlist,
            client_subs: typeof client_subs == "undefined" ? "" : client_subs,
          });
          // newlist.map(i => {
          //   if (i.status == "active") {
          //     this.setState({
          //       purchased_price: i.price,
          //       purchased_id: i.id,
          //       subscription: newlist,
          //       duration: i.duration,
          //       client_subs: client_subs
          //     });
          //   }
          // });
        }
      }
    });
  }

  // getPriceTableData() {
  //   var d = localStorage.getItem("user_data");
  //   var u_data = {};
  //   if (d) {
  //     u_data = JSON.parse(d);
  //   }
  //   //  console.log("vv  ", u_data.id);
  //   if (u_data) {
  //     var data = {
  //       id: u_data.id
  //     };
  //   } else {
  //     var data = {
  //       id: localStorage.getItem("parent_id")
  //     };
  //   }

  //   Utility.ExecuteData("findclientbyuserid", data).then(res => {
  //     if (res) {
  //       // //consolee.log(res.data);
  //       localStorage.setItem("client_id", res.data.client_id);
  //       this.setState({
  //         client_id: res.data.client_id
  //       });
  //     }
  //     var client_id;
  //     var data;
  //     client_id = localStorage.getItem("client_id");
  //     var parent_id = localStorage.getItem("parent_id");
  //     data = {
  //       client_id: client_id,
  //       id: u_data.id,
  //       parent_id: parent_id
  //     };
  //     //consolee.log(data);
  //     Utility.ExecuteData("subscriptionlistforclit", data).then(res => {
  //       //consolee.log(res);
  //       this.setState(() => ({
  //         datalist: data ? data.data : ""
  //       }));

  //       var list = res.data;
  //       let newlist = new Array();
  //       if (list) {
  //         list.map((v, k) => {
  //           var newfeature = new Array();
  //           if (typeof newlist[v.id] != "undefined") {
  //             newfeature = newlist[v.id].feature;
  //           }
  //           newfeature.push(v.title);
  //           newlist[v.id] = {
  //             id: v.id,
  //             title: v.subscriptions,
  //             price: v.price,
  //             feature: newfeature,
  //             status: v.status,
  //             duration: v.duration
  //           };
  //         });
  //         newlist.sort(this.GetSortOrder("price"));
  //         console.log(newlist)
  //         this.setState({
  //           subscription: newlist
  //         });
  //         newlist.map(i => {
  //           if (i.status == "active") {
  //             this.setState({
  //               purchased_price: i.price,
  //               purchased_id: i.id,
  //               subscription: newlist,
  //               duration: i.duration
  //             });
  //           }
  //         });
  //       }
  //     });
  //   });
  // }
  GetSortOrder(prop) {
    return function(a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }
  checkSearchDomain(id) {
    var verified = localStorage.getItem("verified");

    if (verified == "1") {
      this.setState({
        checkout_id: id,
        redirect: true,
      });
    } else {
      this.toggle("", id);

      // this.setState({
      //   showsuccess: true
      // });
    }
  }

  render() {
    ////consolee.log("chkout ", this.state.checkout_id);
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to={"/checkout/" + this.state.checkout_id} />;
    }

    return (
      <div className="pricing_tabel_section container" id="priceTable">
        <h1 style={{ color: "rgba(152,26,155,1)", "font-family": "Gotham" }}>
          PRICING
        </h1>
        <h6
          style={{
            color: "rgba(83,34,84,1)",
            fontSize: "15px",
            "font-family": "Gotham-Light",
            fontWeight: "390",
            marginBottom: "20px",
          }}
        >
          CSAE Digital Advocacy is an annual member subscription. Your
          subscription includes access to the full suite of digital tools
          offered in our package, and any new services we introduce. Run up to 4
          bilingual campaigns with one basic subscription. Add campaigns as
          needed with new support tiers.
        </h6>

        <h6
          style={{
            color: "rgba(83,34,84,1)",
            fontSize: "15px",
            "font-family": "Gotham-Light",
            fontWeight: "390",
            marginBottom: "20px",
          }}
        >
          Most importantly, money from every subscription sold will be
          reinvested in programming and services for Canadian associations.
        </h6>

        {this.state.subscription
          ? this.state.subscription.map((val, key) => {
              return (
                <div>
                  <h6
                    style={{
                      color: "rgba(152,26,155,1)",
                      "font-family": "Gotham-Light",
                      fontWeight: "400",
                    }}
                  >
                    {val.title}
                  </h6>
                  <h6
                    style={{
                      color: "rgba(83,34,84,1)",
                      fontSize: "15px",
                      "font-family": "Gotham-Light",
                      fontWeight: "390",
                      marginBottom: "20px",
                    }}
                  >
                    {val.description}{" "}
                  </h6>

                  <table
                    className="tabel_wrap table-responsive"
                    style={{ border: "1px solid black", width: "100%" }}
                  >
                    <tr style={{ background: "rgba(152,26,155,1)" }}>
                      {/* <th style={{ 'border': '1px solid #d1cfcf', 'padding': '10px', 'color': 'white' }}>Title</th> */}
                      <th
                        style={{
                          border: "1px solid #d1cfcf",
                          padding: "10px",
                          color: "white",
                          width: "240px",
                          "text-align": "center",
                        }}
                      >
                        Price
                      </th>
                      <th
                        style={{
                          border: "1px solid #d1cfcf",
                          padding: "10px",
                          color: "white",
                          width: "240px",
                          "text-align": "center",
                        }}
                      >
                        Duration
                      </th>
                      <th
                        style={{
                          border: "1px solid #d1cfcf",
                          padding: "10px",
                          color: "white",
                          width: "240px",
                          "text-align": "center",
                        }}
                      >
                        Total Price
                      </th>
                      <th
                        style={{
                          border: "1px solid #d1cfcf",
                          padding: "10px",
                          color: "white",
                          width: "240px",
                          "text-align": "center",
                        }}
                      >
                        Features
                      </th>
                      <th
                        style={{
                          border: "1px solid #d1cfcf",
                          padding: "10px",
                          color: "white",
                          width: "240px",
                          "text-align": "center",
                        }}
                      >
                        Purchase
                      </th>
                      {/* <th style={{ 'border': '1px solid #d1cfcf', 'padding': '10px', 'color': 'white' }}>Description</th> */}
                    </tr>

                    <tr style={{ background: "rgba(205,212,234,1)" }}>
                      {/* <td style={{ 'border': '1px solid white', 'padding': '10px' }}>{val.title}</td> */}
                      <td
                        style={{
                          border: "1px solid white",
                          padding: "10px",
                          "text-align": "center",
                        }}
                      >
                        ${val.price}
                      </td>
                      <td
                        style={{
                          border: "1px solid white",
                          padding: "10px",
                          "text-align": "center",
                        }}
                      >
                        {val.duration}
                      </td>
                      <td
                        style={{
                          border: "1px solid white",
                          padding: "10px",
                          "text-align": "center",
                        }}
                      >
                        ${val.price}
                      </td>
                      <td
                        style={{
                          border: "1px solid white",
                          padding: "10px",
                          "text-align": "center",
                        }}
                      >
                        {val.feature.join(", ")}
                      </td>
                      <td
                        style={{
                          border: "1px solid white",
                          padding: "10px",
                          position: "relative",
                          "text-align": "center",
                        }}
                      >
                        {/* <a href="mailto:rob@pushpolitics.ca" target="blank" className={"btn btn-primary"} style={{cursor:"pointer", paddingTop:"20px"}}>CONTACT US</a> */}
                        {this.state.client_subs != "" &&
                        this.state.client_subs.length > 0 &&
                        this.state.client_subs[0].id != val.id ? (
                          <button
                            className="buy_now"
                            // onClick={() => this.checkSearchDomain(val.id)}
                            disabled
                            style={{
                              cursor: "not-allowed",
                              "background-color": "#333333",
                            }}
                          >
                            Buy Now
                          </button>
                        ) : this.state.client_subs != "" &&
                          this.state.client_subs[0].id == val.id ? (
                          <button
                            disabled
                            style={{
                              cursor: "not-allowed",
                              "background-color": "#9c8f8f",
                            }}
                          >
                            Purchased
                          </button>
                        ) : (
                          <button
                            className="buy_now"
                            onClick={() => this.checkSearchDomain(val.id)}
                          >
                            Buy Now
                          </button>
                        )}
                      </td>
                      {/* <td style={{ 'border': '1px solid white', 'padding': '10px' }}>{val.description}</td> */}
                    </tr>
                  </table>
                </div>
              );
            })
          : ""}
        {/*        
        <h6 style={{ 'color': 'rgba(152,26,155,1)' }}>NON MEMBERS</h6>
        <h6 style={{ 'color': 'rgba(83,34,84,1)', 'fontSize': '15px', 'fontWeight': '400', 'marginBottom': '20px' }}>CSAE aims to deliver the highest level of service to Canadian associations. For non-members hoping to improve their advocacy <br /> efforts, CSAE offers a free membership ($575 value) to any non-member. Growth through service delivery will improve CSAE’s ability <br /> to deliver even more valuable programming  </h6>


        <table className="tabel_wrap" style={{ 'border': '1px solid black', 'width': '100%' }}>

          <tr style={{ 'background': 'rgba(152,26,155,1)' }}>

            <th style={{ 'border': '1px solid #d1cfcf', 'padding': '10px', 'color': 'white' }}>Regular Price</th>
            <th style={{ 'border': '1px solid #d1cfcf', 'padding': '10px', 'color': 'white' }}>CSAE Member Discount</th>
            <th style={{ 'border': '1px solid #d1cfcf', 'padding': '10px', 'color': 'white' }}>Total Price</th>
            <th style={{ 'border': '1px solid #d1cfcf', 'padding': '10px', 'color': 'white' }}>Your Savings</th>
          </tr>


          <tr style={{ 'background': 'rgba(205,212,234,1)' }}>
            <td style={{ 'border': '1px solid white', 'padding': '10px' }}>$2800</td>
            <td style={{ 'border': '1px solid white', 'padding': '10px' }}>10%</td>
            <td style={{ 'border': '1px solid white', 'padding': '10px' }}>$2800</td>
            <td style={{ 'border': '1px solid white', 'padding': '10px' }}>$575</td>
          </tr>

        </table> */}

        {/* <div className="price_plan_headline" id="priceTable">
          <h1> Plans that scale with you.</h1>
          <h5>Pay annually - save up to 20%</h5>
        </div>
        <div className="flex-container">
          {this.state.subscription
            ? this.state.subscription.map((val, key) => {
                // //consolee.log(val)
                return (
                  <div className="flex-item pos_rel">
                    <ul className="package">
                      <li className="header" key={key}>
                        {val.title}
                      </li>
                      <div className="price_overflow">
                        <li key="feature">
                          {val.feature.map((i, key) => {
                            ////consolee.log(i)
                            return <li key={key}>{i}</li>;
                          })}
                        </li>
                      </div>
                      <div className="pos_abs_btm">
                        <li className="gray" key={key + "price"}>
                          ${val.price} /{val.duration} days
                        </li>
                        {this.props.user_type !== "admin" && this.props.user_type !== "reseller" ? (
                          this.props.buttonType ? (
                            <li className="gray" key={key + "btn"}>
                              {val.status == "active" ? (
                                <button
                                  onClick={() => this.checkSearchDomain(val.id)}
                                  disabled
                                  style={{
                                    cursor: "not-allowed",
                                    "background-color": "#333333"
                                  }}
                                >
                                  purchased
                                </button>
                              ) : val.price > this.state.purchased_price ? (
                                <button onClick={() => this.checkSearchDomain(val.id)}>buy now</button>
                              ) : (
                                <button
                                  disabled
                                  style={{
                                    cursor: "not-allowed",
                                    "background-color": "#9c8f8f"
                                  }}
                                >
                                  buy now
                                </button>
                              )}
                            </li>
                          ) : (
                            <li className="gray" key={key + "signup"}>
                              <button onClick={() => this.toggle("", val.id)}>SignUp</button>
                            </li>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </ul>
                  </div>
                );
              })
            : ""}
        </div>
      
      */}
        <LoginPopUp
          isOpen={this.state.modal}
          toggle={this.toggle}
          chckout={this.state.checkoutID}
          getid={this.props.getuser}
          getPriceTableData={this.getPriceTableData}
        />
        <SweetAlert
          type="warning"
          title="Please verify your email id!"
          show={this.state.showsuccess}
          onConfirm={this.showRegisterMsg}
        />
      </div>
    );
  }
}
