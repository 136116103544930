import React from "react";
import { NavLink } from "react-router-dom";
import content from "../../content";
export default class Footer extends React.Component {
  render() {
    let host = window.location.host;
    let domainContent = content[host];
    return (
      <div>
        <footer className="footer_main">
          <div className="footer_nav_container">
            <div className="footer_nav_list">
              <h4 className="footer_nav_titles">
                SUPPORT
                <div className="footer_nav_arrow"></div>
              </h4>
              <div className="footer_nav_content">
                <a className="footer_nav_link" href="#">
                  Service Status
                </a>
                <a className="footer_nav_link" href="#">
                  @{host.replace("www.", "")}
                </a>
              </div>
            </div>
            {/* <div className="footer_nav_list">
      <h4 className="footer_nav_titles">
         COMMUNITY
         <div className="footer_nav_arrow"></div>
      </h4>
      <div className="footer_nav_content">
         <a className="footer_nav_link"  href="#">Forum</a>
         <a className="footer_nav_link"  href="#">Hire an Expert</a>
         <a className="footer_nav_link "  href="#">Webinars &amp; Workshops</a>
         <a className="footer_nav_link"  href="#">Blog</a>
      </div>
   </div> */}
            <div className="footer_nav_list">
              <h4 className="footer_nav_titles">
                RESOURCES
                <div className="footer_nav_arrow"></div>
              </h4>
              <div className="footer_nav_content">
                {/* <a className="footer_nav_link"  href="#">Website Design</a>
         <a className="footer_nav_link" href="#">Website Marketing</a> */}
                <a className="footer_nav_link" href="#">
                  Terms of Service
                </a>
                <a className="footer_nav_link" href="#">
                  Privacy Policy
                </a>
              </div>
            </div>
            <div className="footer_nav_list">
              <h4 className="footer_nav_titles">
                CONTACT US
                <div className="footer_nav_arrow"></div>
              </h4>
              <div className="footer_nav_content">
                <a className="footer_nav_link" href="#">
                  {domainContent.title}
                </a>
                <a className="footer_nav_link" href="#">
                  Mail : contact@{host.replace("www.", "")}
                </a>
                <a className="footer_nav_link" href="#">
                  Contact - 0123456789
                </a>
                <a className="footer_nav_link" href="#">
                  Address : D 105,CA
                  <br />
                  Canada(CA), 500001
                </a>
              </div>
            </div>
          </div>
          <div className=" footer_second_row">
            <h1 className="footer_nav_link"> © 2018 {domainContent.title} </h1>
            <ul className="social_connect">
              <li>
                <img src="/Assets/images/facebook-logo.png" className="" />
              </li>
              <li>
                <img
                  src="/Assets/images/twitter-logo-silhouette.png"
                  className=""
                />
              </li>
            </ul>
          </div>
        </footer>
      </div>
    );
  }
}
