import React from "react";
class UserLoginButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return this.props.buttontype ? (
      <li>
        <a className="text_gray">{"Hi, " + this.props.buttontype}</a>
        <a className="login_txt cursorpointer" onClick={this.props.logout}>
          LogOut
        </a>
        {/* <img src="/Assets/images/wwf.png" className="profile_place"/> */}
      </li>
    ) : (
      <li>
        <a href="#" className="login_txt" onClick={this.props.toggle}>
          Login/SignUp
        </a>
      </li>
    );
  }
}
export default UserLoginButton;
