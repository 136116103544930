import React from "react";
import Utility from "../common/Utility";
import { Redirect } from "react-router-dom";
import TopHeader from "../menu/TopHeader";
import { __t } from "../../local/Language";
import bcrypt from "bcryptjs";
import SweetAlert from "react-bootstrap-sweetalert";
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      errors: {},
      lang: "en",
      show: false,
      password: "",
      id: "",
      showsuccess: false,
      redirect: false,
      redirectToforgot: false,
      login_url:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.ResethandleValidation = this.ResethandleValidation.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.createHash = this.createHash.bind(this);
    this.showSuccessMsg = this.showSuccessMsg.bind(this);
    this.forgot_toggle = this.forgot_toggle.bind(this);
  }
  componentWillMount() {
    // var authData = Utility.getIdFromToken();
    // if (authData) {
    //   this.setState({
    //     id: authData.id
    //   });
    // } else {
    //   this.setState({
    //     show: true
    //   });
    // }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  ResethandleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (fields.errors === "not valid") {
      formIsValid = false;
      errors["reset_password"] = __t("please_enter_password.");
    }
    if (!fields["reset_password"]) {
      formIsValid = false;
      errors["reset_password"] = __t("message_pwd_empty");
    }

    if (fields["reset_password"] !== fields["confirm_password"]) {
      formIsValid = false;
      errors["reset_password"] = __t(
        "password_and_confirm_password_mustbe_same"
      );
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  changePassword() {
    //console.log(this.props.match.params.token)

    if (this.ResethandleValidation()) {
      if (this.state.reset_password) {
        this.createHash(this.state.reset_password).then(myhash => {
          this.setState({
            password: myhash
          });
          // password reset token, hash password
          fetch(Utility.baseUrl() + "resetPassword", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            // body: JSON.stringify(this.state)

            body: JSON.stringify({
              password: this.state.password,
              token: this.props.match.params.token
            })
          })
            .then(responseJson => {
              //console.log(responseJson.status)
              if (responseJson.status == 200) {
                this.setState({
                  showsuccess: true,
                  login_url:true
                });
              }
              else {
                this.setState({
                  show: true
                });
              }
            })
            .catch(error => {
              this.setState({ errors: true });
            });
        });
      }
    } else {
    }
  }

  createHash(pwd) {
    const saltRounds = 10;
    return new Promise((resolve, reject) => {
      bcrypt.genSalt(saltRounds, function (err, salt) {
        if (err) return err;
        bcrypt.hash(pwd, salt, function (err, hash) {
          if (err) {
            reject(err);
          } else {
            resolve(hash);
          }
        });
      });
    });
  }
  showSuccessMsg() {
    this.setState({
      showsuccess: false,
      redirect: true
    });
  }
  forgot_toggle() {
    this.setState({
      redirectToforgot: true
    });
  }

  render() {
  
    const { login_url } = this.state;
    if(login_url){
      return <Redirect push to="/" />;
    }
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to="/reset_login" />;
    }
    const { redirectToforgot } = this.state;
    if (redirectToforgot) {
      return <Redirect push to="/forgot_login" />;
    }
    return (
      <div className="reset_wrapp">
        <TopHeader
          buttonType={this.props.ButtonType}
          getidfromtoken={this.props.getUserInfo}
        />
        <div className="resetpassword">
          <h2 className="reset_txt">Reset Password </h2>
          {this.state.show ? (
            <div className="reset_token">
              Token has been Expire/Invalid , Please create your password token
              Again.
              <button className="token_btn" onClick={this.forgot_toggle}>
                Cliek here
              </button>
            </div>
          ) : (
              <div>
                <div className="form-group pos_rel">
                  <img src="/Assets/images/lock.png" className="input_img" />
                  <input
                    type="password"
                    name="reset_password"
                    className="form-control paddingleft40"
                    placeholder="New Password"
                    required=""
                    onChange={this.handleChange}
                  //  value={this.state.password}
                  />
                </div>
                <span style={{ color: "red" }}>
                  {this.state.errors["reset_password"]}
                </span>
                <div className="form-group pos_rel">
                  <img src="/Assets/images/lock.png" className="input_img" />
                  <input
                    type="password"
                    name="confirm_password"
                    className="form-control paddingleft40"
                    placeholder="Confirm Password"
                    required=""
                    data-match="#reset_password"
                    data-match-error="Whoops, these don't match"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="flx_align_center mtop_10">
                  <button
                    type="submit"
                    className="btn btn_orange block full-width m-b"
                    onClick={this.changePassword}
                  >
                    Submit
                </button>
                </div>
              </div>
            )}{" "}
        </div>

        <SweetAlert
          success
          title="Password change successfully!"
          show={this.state.showsuccess}
          onConfirm={this.showSuccessMsg}
        >
          please click here to login
        </SweetAlert>
      </div>
    );
  }
}

export default ResetPassword;
